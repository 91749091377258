import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, selectAllProducts, selectProductsAreLoading, selectProductsPages } from 'store/slices/marketplace';
import { useSearchParams } from 'react-router-dom';

// Components
import FullScreenLoader from 'components/FullScreenLoader';
import MarketplaceSection from 'components/MarketplaceSection';
import Pagination from 'components/Pagination';


const Marketplace = ({
  className,
  link,
  title
}) => {
  const dispatch = useDispatch();
  const products = useSelector(selectAllProducts);
  const productsAreLoading = useSelector(selectProductsAreLoading);
  const productsPages = useSelector(selectProductsPages);

  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [params, setParams] = useSearchParams();

  const pageNumber = params.get('page');

  const handleNextPageClick = () => {
    dispatch(getProducts(nextPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os produtos.');

        return response;
      })
      .then((response) => {
        setParams({ page: nextPageNumber });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handlePreviousPageClick = () => {
    dispatch(getProducts(previousPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os produtos.');

        return response;
      })
      .then((response) => {
        if (previousPageNumber) setParams({ page: previousPageNumber });
        else params.delete('page');
        setParams(params);

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (productsPages) {
      if (productsPages.next) {
        const nextNumber = productsPages.next.split('page=');

        if (nextNumber[1]) setNextPageNumber(nextNumber[1]);
      }

      if (productsPages.previous) {
        const previousNumber = productsPages.previous.split('page=');

        if (previousNumber[1]) setPreviousPageNumber(previousNumber[1]);
      }
    }
  }, [productsPages]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(getProducts(pageNumber))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os produtos.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      dispatch(getProducts())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os produtos.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((!Array.isArray(products) || !products.length)) return null;

  return productsAreLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <MarketplaceSection
          items={products}
          link={link}
          title={title}
        />
        {!!productsPages && (
          <Pagination
            className="mt-10"
            nextPage={productsPages.next}
            previousPage={productsPages.previous}
            onNextPageClick={handleNextPageClick}
            onPreviousPageClick={handlePreviousPageClick}
          />
        )}
      </div>
    );
};

Marketplace.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string
};

Marketplace.defaultProps = {
  className: null,
  title: null
};


export default Marketplace;
