import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUserProfile } from 'store/slices/user';

// Utils
import { mobileMenuItems } from 'utils/MenuItems';

// Components
import BaseLink from 'components/BaseLink';


const MobileMenu = ({ onLinkClick }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector(selectUserProfile);

  const handleLogOutClick = () => dispatch(logout());

  if (!Array.isArray(mobileMenuItems) || !mobileMenuItems.length) return null;

  const publicMenuItems = mobileMenuItems.filter(item => item.showWithoutUserProfile);
  const menuItemsToShow = userProfile.username ? mobileMenuItems : publicMenuItems;

  const activeLinkStyles = 'pointer-events-none opacity-100 outline-none';
  const storeLinkStyle = 'text-center px-8 py-3 rounded-xl border border-white opacity-100 active:bg-transparent focus:bg-transparent';

  return (
    <div className="xl:hidden min-h-screen">
      <ul className="inline-flex flex-col gap-4 py-10">
        {Array.isArray(menuItemsToShow) && menuItemsToShow.map((menuLink, menuLinkIndex) => (
          <React.Fragment key={menuLinkIndex}>
            <li className="flex">
              <BaseLink
                className={`font-normal text-xl${menuLink.path === '/loja' ? ` ${storeLinkStyle} ` : ''}${location.pathname === menuLink.path ? ` ${activeLinkStyles}` : ''}`}
                path={menuLink?.path}
                text={menuLink?.text}
                textColor={menuLink?.textColor}
                onClick={menuLink?.action === 'logout' ? handleLogOutClick : onLinkClick}
              />
            </li>
            {menuLink.path === '/loja' && (
              <div className="relative h-[1px] w-8 bg-border-gray my-3 z-10" />
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

MobileMenu.propTypes = {
  onLinkClick: PropTypes.func
};

MobileMenu.defaultProps = {
  onLinkClick: () => {}
};

export default MobileMenu;
