import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

// Store
import { getUserDetails, selectUserIsLoading, selectUserProfile, setUserDetails } from 'store/slices/user';

// Components
import BaseButton from 'components/BaseButton';
import BaseInput from 'components/BaseInput';
import FullScreenLoader from 'components/FullScreenLoader';
import BaseSelect from './BaseSelect';


const companyOptions = [
  {
    text: 'Alves Bandeira',
    value: 1
  },
  {
    text: 'Petroibérica',
    value: 2
  },
  {
    text: 'AB Tyres',
    value: 3
  },
  {
    text: 'AB International',
    value: 4
  },
  {
    text: 'AB Chem',
    value: 5
  },
  {
    text: 'AB Lubs',
    value: 6
  },
  {
    text: 'AB Power',
    value: 7
  },
  {
    text: 'Prediband',
    value: 8
  },
  {
    text: 'Alves Bandeira ACE',
    value: 9
  }
];

const ProfileForm = ({
  className
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userIsLoading = useSelector(selectUserIsLoading);
  const userProfile = useSelector(selectUserProfile);
  const hasUsername = userProfile?.username;

  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const [formSuccessMessage, setFormSuccessMessage] = useState(null);

  useEffect(() => {
    dispatch(getUserDetails())
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  const { handleSubmit, formState: { errors }, control, register, setError, setFocus, setValue, watch } = useForm({
    defaultValues: {
      address: '',
      company: '',
      firstName: '',
      height: '',
      lastName: '',
      locality: '',
      postalCode: '',
      telephone: '',
      username: '',
      weight: ''
    },
    reValidateMode: 'onChange'
  });

  const handleInputChange = (value, field) => {
    field.onChange(value);
  };

  const isFormValid = () => {
    const inputs = ['firstName'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const handleFormSubmit = (data) => {
    if (isFormValid) {
      dispatch(setUserDetails(data))
        .then((response) => {
          const { error, payload } = response;

          if (error) {
            if (payload.username) {
              setError('username', {
                message: 'O username que inseriu já se encontra em uso. Por favor insira outro.',
                type: 'custom'
              });

              setFocus('username');
            } else if (payload.height) {
              setError('height', {
                message: 'Insira um valor válido.',
                type: 'custom'
              });

              setFocus('height');
            } else if (payload.weight) {
              setError('weight', {
                message: 'Insira um valor válido.',
                type: 'custom'
              });

              setFocus('weight');
            } else if (payload.telephone) {
              setError('telephone', {
                message: 'Certifique-se de que este campo não tenha mais de 9 caracteres.',
                type: 'custom'
              });

              setFocus('telephone');
            } else {
              window.scrollTo({
                behavior: 'smooth',
                top: 0
              });

              setFormErrorMessage(response.error.message || 'Ocorreu um erro ao guardar os seus dados. Por favor tente novamente.');
            }
          } else if (hasUsername) {
            window.scrollTo({
              behavior: 'smooth',
              top: 0
            });
            setFormSuccessMessage('Dados guardados com sucesso!');
          } else {
            navigate('/dashboard');
          }

          return response;
        });
    }
  };

  useEffect(() => {
    const errorsValues = Object.values(errors);

    if (errorsValues.length > 0) {
      errorsValues[0].ref.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    setValue('address', userProfile?.address);
    setValue('company', userProfile?.company);
    setValue('firstName', userProfile?.firstName);
    setValue('height', userProfile?.height);
    setValue('lastName', userProfile?.lastName);
    setValue('locality', userProfile?.locality);
    setValue('postalCode', userProfile?.postalCode);
    setValue('telephone', userProfile?.telephone);
    setValue('username', userProfile?.username);
    setValue('weight', userProfile?.weight);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  return (
    <>
      {userIsLoading && <FullScreenLoader />}
      <form
        className={`${className ? ` ${className}` : ''}`}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        {!!formErrorMessage && (
          <div className="rounded-md bg-error p-3 mb-5">
            <p className="text-white">
              {formErrorMessage}
            </p>
          </div>
        )}
        {!!formSuccessMessage && (
          <div className="rounded-[8px] p-3 mb-5 bg-green-600">
            <p className="text-white">
              {formSuccessMessage}
            </p>
          </div>
        )}
        <Controller
          control={control}
          name="username"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              disabled={!!userProfile?.username}
              error={error ? error.message : ''}
              label="Username"
              placeholder="O seu username"
              type="text"
              {...register('username')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
          rules={{
            required: 'Insira o seu username.'
          }}
        />
        <Controller
          control={control}
          name="firstName"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Nome"
              placeholder="O seu nome"
              type="text"
              {...register('firstName')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
          rules={{
            required: 'Insira o seu nome.'
          }}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Apelido"
              placeholder="O seu apelido"
              type="text"
              {...register('lastName')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
          rules={{
            required: 'Insira o seu apelido.'
          }}
        />
        <Controller
          control={control}
          name="height"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Altura (m)"
              placeholder="A sua altura em metros"
              type="text"
              {...register('height')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <Controller
          control={control}
          name="weight"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Peso (kg)"
              placeholder="O seu peso em kg"
              type="text"
              {...register('weight')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Morada"
              placeholder="A sua morada"
              type="text"
              {...register('address')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <Controller
          control={control}
          name="postalCode"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Código Postal"
              placeholder="O seu código postal"
              type="text"
              {...register('postalCode')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <Controller
          control={control}
          name="locality"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Localidade"
              placeholder="A sua localidade"
              type="text"
              {...register('locality')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <Controller
          control={control}
          name="telephone"
          render={({ field, fieldState: { error } }) => (
            <BaseInput
              className="mt-3"
              error={error ? error.message : ''}
              label="Telemóvel"
              placeholder="O seu número de telemóvel"
              type="number"
              {...register('telephone')}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <Controller
          control={control}
          name="company"
          render={({ field, fieldState: { error } }) => (
            <BaseSelect
              className="mt-3"
              error={error ? error.message : ''}
              label="Empresa"
              placeholder="A sua empresa"
              {...register('company')}
              options={companyOptions}
              onChange={event => handleInputChange(event?.target?.value, field)}
            />
          )}
        />
        <BaseButton
          className="mt-5 w-full"
          text={hasUsername ? 'Guardar' : 'Continuar'}
          type="submit"
        />
      </form>
    </>
  );
};

ProfileForm.propTypes = {
  className: PropTypes.string
};

ProfileForm.defaultProps = {
  className: null
};


export default ProfileForm;
