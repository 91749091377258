import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeClass, getChallenges, getClassComments, getClasses, getLiveClasses, selectAllChallenges, selectAllClasses, selectAllLiveClasses, selectClassIsLoading, submitVideo } from 'store/slices/classes';
import { selectUserProfile } from 'store/slices/user';

// Components
import Card from 'components/Card';
import ClassRatingAndComment from 'components/ClassRatingAndComment';
import Video from 'components/Video';
import SubmitParticipation from 'components/SubmitParticipation';
import FullScreenLoader from 'components/FullScreenLoader';


const Class = ({
  className,
  noShadow
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const classes = useSelector(selectAllClasses);
  const classIsLoading = useSelector(selectClassIsLoading);
  const challenges = useSelector(selectAllChallenges);
  const liveClasses = useSelector(selectAllLiveClasses);
  const user = useSelector(selectUserProfile);

  const [allClasses, setAllClasses] = useState([]);
  const [currentClass, setCurrentClass] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [itemToShow, setItemToShow] = useState(null);
  const [videoSubmissionError, setVideoSubmissionError] = useState(false);

  const formData = new FormData();

  useEffect(() => {
    if (Array.isArray(classes) && !!classes.length) {
      setAllClasses(prevState => [...prevState, ...classes]);
    }
  }, [classes]);

  useEffect(() => {
    if (Array.isArray(liveClasses) && !!liveClasses.length) {
      setAllClasses(prevState => [...prevState, ...liveClasses]);
    }
  }, [liveClasses]);

  useEffect(() => {
    if (Array.isArray(allClasses)) {
      setCurrentClass(allClasses.find(item => item.id.toString() === id));
    }
  }, [allClasses, id]);

  useEffect(() => {
    if (Array.isArray(allClasses)) {
      setCurrentClass(allClasses.find(item => item.id.toString() === id));
    }
  }, [allClasses, id]);

  useEffect(() => {
    if (Array.isArray(challenges)) {
      setCurrentChallenge(challenges.find(item => item.id.toString() === id));
    }
  }, [challenges, id]);

  useEffect(() => {
    setItemToShow(location.pathname.includes('/aula') ? currentClass : currentChallenge);
  }, [currentChallenge, currentClass, location.pathname]);

  useEffect(() => {
    if (itemToShow) {
      setCurrentItem(itemToShow);
    }
  }, [itemToShow]);

  useEffect(() => {
    dispatch(getClasses())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    dispatch(getChallenges())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os desafios.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });

    dispatch(getLiveClasses())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas ao vivo.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClassComments(id))
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch, id]);

  const handleFeedbackSubmit = (newComment, newRating) => {
    dispatch(changeClass({
      challenge: parseInt(id, 10),
      comment: newComment,
      rating: newRating,
      username: user?.username
    }))
      .then((response) => {
        setCurrentItem({
          ...itemToShow,
          comment: newComment,
          rating: newRating
        });
        dispatch(getClassComments(id))
          .then((resp) => {
            if (resp?.error?.message === '401') {
              dispatch(getClassComments(id));
            }

            return resp;
          })
          .then(resp => resp)
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleSubmitParticipation = (file) => {
    formData.append('username', user?.username);
    formData.append('challenge', parseInt(id, 10));
    formData.append('video', file);

    dispatch(submitVideo(formData))
      .then((response) => {
        dispatch(getClasses());
        dispatch(getChallenges());

        return response;
      })
      .catch((error) => {
        setVideoSubmissionError(true);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleVideoEnd = () => {
    if (currentClass) {
      dispatch(changeClass({
        challenge: parseInt(id, 10),
        points: itemToShow.points,
        state: 2,
        username: user?.username
      }))
        .then((response) => {
          dispatch(getClasses());
          dispatch(getChallenges());

          return response;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  if (!currentItem) return null;

  const currentDate = new Date();
  const itemEndLiveDate = new Date(itemToShow.liveEndDate);
  const itemStartLiveDate = new Date(itemToShow.liveStartDate);

  const classIsAvailableToPlay = !itemToShow.liveStartDate || (currentDate >= itemStartLiveDate && currentDate <= itemEndLiveDate);

  if (!classIsLoading && !itemToShow) {
    return (
      <p>
        There was an error. Please try again.
      </p>
    );
  }

  const displayDate = itemToShow.challengeType === '3' && new Date(itemToShow.liveStartDate)?.toLocaleString('pt-pt', { dateStyle: 'short', timeStyle: 'short' });

  return classIsLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <>
        <Card
          className={className ? className : ''}
          noShadow={noShadow}
        >
          <>
            {(!!itemToShow.duration || !!itemToShow.liveStartDate || !!itemToShow.activeMonth) && (
              <div className="flex items-center justify-between">
                {(!!itemToShow.duration || !!itemToShow.activeMonth || !!itemToShow.liveStartDate) && (
                  <div className="flex mb-3">
                    {!itemToShow.liveStartDate && (
                      <div className="text-xs text-light-blue border border-light-blue rounded-3xl px-2 py-1 mr-2">
                        {itemToShow.activeMonth}
                      </div>
                    )}
                    {!!displayDate && (
                      <div className="text-xs text-light-blue border border-light-blue rounded-3xl px-2 py-1 mr-2">
                        {displayDate}
                      </div>
                    )}
                    <div className="text-xs text-light-blue border border-light-blue rounded-3xl px-2 py-1 mr-2">
                      {itemToShow.duration}
                    </div>
                  </div>
                )}
              </div>
            )}
            <h2>
              {itemToShow.title}
            </h2>
            <p className="font-semibold text-orange">
              {itemToShow.points}
              {' '}
              pontos
            </p>
            <Video
              image={itemToShow.image}
              playIsAvailable={classIsAvailableToPlay}
              videoLink={itemToShow.videoLink}
              onEnd={handleVideoEnd}
            />
            {!!itemToShow.summary && (
              <p className="text-text-gray mt-3">
                {itemToShow.summary}
              </p>
            )}
          </>
        </Card>
        {currentChallenge && (
          <SubmitParticipation
            className="mt-5"
            error={videoSubmissionError}
            state={itemToShow.state}
            onSubmit={handleSubmitParticipation}
          />
        )}
        <ClassRatingAndComment
          className="mt-5"
          comment={itemToShow.comment}
          rating={itemToShow.rating}
          state={itemToShow.state}
          onSubmit={handleFeedbackSubmit}
          {...currentItem}
        />
      </>
    );
};

Class.propTypes = {
  className: PropTypes.string,
  noShadow: PropTypes.bool
};

Class.defaultProps = {
  className: null,
  noShadow: false
};


export default Class;
