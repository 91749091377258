import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseLink from 'components/BaseLink';
import Card from 'components/Card';

// Icons
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';


const ClassCard = ({
  className,
  duration,
  finished,
  id,
  image,
  liveStartDate,
  month,
  noShadow,
  points,
  title,
  type
}) => {
  let classPath = null;

  switch (type) {
    case '2':
      classPath = 'desafio';
      break;
    case '1':
    case '3':
    default:
      classPath = 'aula';
  }

  const displayDate = type === '3' && new Date(liveStartDate)?.toLocaleString('pt-pt', { dateStyle: 'short', timeStyle: 'short' });

  return (
    <div className={`${className ? ` ${className}` : ''}`}>
      <Card
        className={`h-full${image ? ' pt-8' : ''}`}
        noShadow={noShadow}
        smallPadding
      >
        <>
          {!!image && (
            <div className="absolute top-0 left-0 w-full bg-orange h-3" />
          )}
          {(!!duration || !!displayDate || !!month) && (
            <div className="flex items-center justify-between mb-3">
              {(!!duration || !!month) && (
                <div className="flex">
                  {!!image && (
                    <div className="text-xs text-orange border border-orange rounded-3xl px-2 py-1 mr-1">
                      Aula ao vivo
                    </div>
                  )}
                  {!!month && !displayDate && (
                    <div className="text-xs text-light-blue border border-light-blue rounded-3xl px-2 py-1 mr-1">
                      {month}
                    </div>
                  )}
                  {!!displayDate && (
                    <div className="text-xs text-light-blue border border-light-blue rounded-3xl px-2 py-1 mr-1">
                      {displayDate}
                    </div>
                  )}
                  {!!duration && (
                    <div className="text-xs text-light-blue border border-light-blue rounded-3xl px-2 py-1 mr-1">
                      {duration}
                    </div>
                  )}
                </div>
              )}
              {!!finished && (
                <div className="flex items-center justify-center h-7 w-7 bg-success rounded-full">
                  <CheckIcon className="h-4" />
                </div>
              )}
            </div>
          )}
          <h4 className="grow">
            {title}
          </h4>
          <hr className="h-1 border-top border-border-gray my-5" />
          <div className="flex items-end justify-between">
            {!!points && (
              <p className="font-semibold text-orange">
                {points}
                {' '}
              pontos
              </p>
            )}
            <BaseLink
              arrowPosition="right"
              hasArrow
              path={`/${classPath}/${id}`}
              text="Mais detalhes"
              textColor="dark-blue"
            />
          </div>
        </>
      </Card>
    </div>
  );
};

ClassCard.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.string,
  finished: PropTypes.bool,
  id: PropTypes.number,
  image: PropTypes.string,
  liveStartDate: PropTypes.string,
  month: PropTypes.string,
  noShadow: PropTypes.bool,
  points: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
};

ClassCard.defaultProps = {
  className: null,
  duration: null,
  finished: false,
  id: null,
  image: null,
  liveStartDate: null,
  month: null,
  noShadow: false,
  type: null
};


export default ClassCard;
