import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Reakit
import { Input } from 'reakit/Input';

// Components
import BaseLink from 'components/BaseLink';
import Card from 'components/Card';

// Icons
import { ReactComponent as Upload } from 'assets/icons/upload.svg';


const SubmitParticipation = ({
  className,
  disabled,
  error,
  state,
  onSubmit
}) => {
  const input = useRef(null);
  const [file, setFile] = useState(null);

  const inputClasses = 'absolute top-0 lef-0 h-full w-full indent-96 hover:cursor-pointer';
  const labelTextClasses = `font-semibold text-light-blue mt-3${disabled ? ' opacity-50' : ''}`;

  const handleFileInputChange = (files) => {
    setFile(files[0]);
  };

  let attachmentInformation = null;

  if (file?.name) {
    attachmentInformation = {
      name: file.name
    };

    if (typeof attachmentInformation.name === 'string') {
      const attachmentInformationEntries = attachmentInformation.name.split('.');

      if (attachmentInformationEntries.length > 1) {
        attachmentInformation = {
          extension: attachmentInformationEntries[1],
          name: attachmentInformationEntries[0]
        };
      }
    }
  }

  if (error) {
    return (
      <Card className={`form-control${className ? ` ${className}` : ''}`}>
        <div className="flex flex-col items-center">
          <h5 className="text-center text-error">
            Ocorreu um ao submeter o vídeo. Por favor tente novamente.
          </h5>
        </div>
      </Card>
    );
  }

  return (
    <Card className={`form-control${className ? ` ${className}` : ''}`}>
      <div className="flex flex-col items-center">
        {state === null ?
          (
            <h5 className="text-center">
                Submeter Participação
            </h5>
          ) :
          (
            <>
              <h5 className="text-center">
                Obrigado pela sua participação!
              </h5>
              <p className="mt-5 text-center">
                A sua submissão será avaliada por um PT.
                <br />
                A atribuição de pontos está sujeita a essa mesma avaliação.
              </p>
            </>
          )}
        {state === null && (
          <>
            <form
              className="relative inline-flex items-center flex-col mt-5"
            >
              <Upload className="text-light-blue" />
              <Input
                ref={input}
                className={inputClasses}
                type="file"
                onChange={event => handleFileInputChange(event?.target?.files)}
              />
            </form>
            {!!attachmentInformation && (
              <div className="text-gray text-sm mt-3 select-none">
                {attachmentInformation?.name}
                {!!attachmentInformation?.extension && (
                  <span className="opacity-50">
                    .
                    {attachmentInformation.extension}
                  </span>
                )}
              </div>
            )}
            <BaseLink
              className={labelTextClasses}
              disabled={!file}
              text="Carregar Video"
              type="button"
              onClick={() => onSubmit(file)}
            />
          </>
        )}
      </div>
    </Card>
  );
};

SubmitParticipation.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onSubmit: PropTypes.func,
  state: PropTypes.string
};

SubmitParticipation.defaultProps = {
  className: null,
  disabled: false,
  error: false,
  onSubmit: () => {},
  state: null
};

export default SubmitParticipation;
