/* eslint-disable camelcase */
import { axiosGet } from 'api';
import {
  TIPS
} from 'api/endpoints';


const fetchNews = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${TIPS}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(TIPS);
};

export default fetchNews;
