import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

// Store
import { recoverUserPasswordThunk } from 'store/slices/user';

// Components
import BaseButton from 'components/BaseButton';
import BaseInput from 'components/BaseInput';


// eslint-disable-next-line max-len
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PasswordRecoverForm = ({
  className
}) => {
  const dispatch = useDispatch();

  const [formError, setFormError] = useState(null);
  const [formSuccessMessage, setFormSuccessMessage] = useState(null);

  const { handleSubmit, formState: { errors }, control, register, watch } = useForm({
    defaultValues: {
      email: ''
    },
    reValidateMode: 'onChange'
  });

  const isFormValid = () => {
    const inputs = ['email'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const handleFormSubmit = (email) => {
    if (isFormValid) {
      dispatch(recoverUserPasswordThunk(email))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao recuperar a sua password. Por favor tente novamente.');

          return response;
        })
        .then((response) => {
          setFormSuccessMessage('Password recuperada com sucesso! Verifique o seu e-mail.');

          return response;
        })
        .catch((error) => {
          setFormError('Ocorreu um erro ao recuperar a sua password. Por favor tente novamente.');
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  return (
    <form
      className={`${className ? ` ${className}` : ''}`}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {!!formError && (
        <div className="text-xs text-error mb-5">
          {formError}
        </div>
      )}
      {formSuccessMessage && (
        <div className="rounded-[8px] p-3 mb-5 bg-green-600">
          <p className="text-white">
            {formSuccessMessage}
          </p>
        </div>
      )}
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <BaseInput
            error={errors.email?.message}
            label="E-mail"
            placeholder="O seu e-mail"
            type="text"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            {...register('email')}
          />
        )}
        rules={{
          pattern: {
            message: 'Insira um e-mail válido.',
            value: EMAIL_PATTERN
          },
          required: 'Insira o seu e-mail.'
        }}
      />
      <BaseButton
        className="mt-5 w-full"
        text="Recuperar Password"
        type="submit"
      />
    </form>
  );
};

PasswordRecoverForm.propTypes = {
  className: PropTypes.string
};

PasswordRecoverForm.defaultProps = {
  className: null
};


export default PasswordRecoverForm;
