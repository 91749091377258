import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import GoBack from 'components/GoBack';
import PasswordRecoverForm from 'components/PasswordRecoverForm';


const PasswordRecover = ({
  className
}) => (
  <div className={`min-h-screen min-w-full flex items-center justify-center bg-gradient-to-r from-blue to-light-blue ${className ? ` ${className}` : ''}`}>
    <div className="container">
      <div className="max-w-sm mx-auto mb-10">
        <h2 className="text-white mb-10">
          Recuperação de Password
        </h2>
        <GoBack textColor="white" />
        <Card className="max-w-sm mx-auto">
          <PasswordRecoverForm />
        </Card>
      </div>
    </div>
  </div>
);

PasswordRecover.propTypes = {
  className: PropTypes.string
};

PasswordRecover.defaultProps = {
  className: null
};


export default PasswordRecover;
