import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import RegisterForm from 'components/RegisterForm';


const Register = ({
  className
}) => (
  <div className={`flex items-center justify-center ${className ? ` ${className}` : ''}`}>
    <div className="container">
      <div className="max-w-sm mx-auto mb-10">
        <h2 className=" mb-10">
          Criar nova password
        </h2>
        <Card className="max-w-sm mx-auto">
          <RegisterForm />
        </Card>
      </div>
    </div>
  </div>
);

Register.propTypes = {
  className: PropTypes.string
};

Register.defaultProps = {
  className: null
};


export default Register;
