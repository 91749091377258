import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLoginBanner, fetchUserDetails, recoverUserPassword, updateUserDetails, updateUserPassword, userLogin } from 'api/authentication';

import Cookies from 'js-cookie';
import LocalStorageManager from 'utils/LocalStorageManager';


// Thunks
export const getUserDetails = createAsyncThunk(
  'user/getUserDetails',
  () => fetchUserDetails()
    .then((response) => {
      let payload = null;

      /* eslint-disable camelcase */
      if (response) {
        payload = {
          ...response,
          firstName: response.firstname,
          lastName: response.lastname,
          postalCode: response.postal_code,
          userRecord: response.user_record
        };
      }
      /* eslint-enable camelcase */

      return payload;
    })
);

export const login = createAsyncThunk(
  'user/login',
  ({ email, password }) => userLogin({ email, password })
    .then((response) => {
      let payload = null;

      if (response) {
        const { access, refresh } = response;

        payload = {
          authenticationToken: access,
          authenticationTokenRefresh: refresh
        };
      }

      return payload;
    })
);

export const getLoginBanner = createAsyncThunk(
  'user/getLoginBanner',
  () => fetchLoginBanner()
    .then((response) => {
      let payload = null;

      if (response) {
        const { image } = response;

        payload = image;
      }

      return payload;
    })
);

export const recoverUserPasswordThunk = createAsyncThunk(
  'user/recoverUserPasswordThunk',
  email => recoverUserPassword(email)
    .then(response => response)
);

export const setUserDetails = createAsyncThunk(
  'user/setUserDetails',
  (data, { rejectWithValue }) => updateUserDetails(data)
    .then((response) => {
      let payload = null;

      /* eslint-disable camelcase */
      if (response) {
        payload = {
          ...response,
          firstName: response.firstname,
          lastName: response.lastname,
          postalCode: response.postal_code,
          username: response.username,
          userRecord: response.user_record
        };
      }
      /* eslint-enable camelcase */

      return payload;
    })
    .catch(error => rejectWithValue(error))
);

export const setUserPassword = createAsyncThunk(
  'user/setUserPassword',
  password => updateUserPassword(password)
    .then(response => response)
);

export const user = createSlice({
  name: 'user',
  initialState: {
    authenticationToken: null,
    authenticationTokenRefresh: null,
    initialized: false,
    isLoadingUser: false,
    isLoadingLoginImage: false,
    isLoadingPasswordRecover: false,
    isLoggingIn: false,
    isSigningUp: false,
    level: null,
    loginImage: null,
    userProfile: null
  },
  reducers: {
    initialize (state) {
      const isLoggedOut = false;

      if (!isLoggedOut) {
        const authenticationToken = LocalStorageManager.getAuthenticationToken() || Cookies.get('csrftoken');
        const userProfile = LocalStorageManager.getUserProfile() || Cookies.get('user_profile');

        if (authenticationToken) state.authenticationToken = authenticationToken;
        if (userProfile) state.userProfile = userProfile;
      }

      state.initialized = true;
    },

    updateAuthenticationToken: (state, action) => ({
      ...state,
      authenticationToken: action.payload
    }),

    logout (state) {
      Cookies.remove('csrftoken');
      Cookies.remove('csrftoken_refresh');
      Cookies.remove('sessionid');
      Cookies.remove('user_profile');
      LocalStorageManager.removeAuthenticationToken();
      LocalStorageManager.removeAuthenticationTokenRefresh();
      LocalStorageManager.removeUserProfile();
      state.authenticationToken = null;
      state.userProfile = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginBanner.pending, (state) => {
        state.isLoadingLoginImage = true;
      })
      .addCase(getLoginBanner.fulfilled, (state, action) => {
        if (action?.payload) {
          state.loginImage = action.payload;
        }

        state.isLoadingLoginImage = false;
      })
      .addCase(getLoginBanner.rejected, (state) => {
        state.isLoadingLoginImage = false;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        if (action?.payload) {
          const { firstName, lastName, level, username } = action.payload;

          if (firstName && lastName && username) {
            state.userProfile = action.payload;
            Cookies.set('user_profile', JSON.stringify(action.payload));
            LocalStorageManager.setUserProfile(action.payload);
          } else {
            state.userProfile = {};
            Cookies.set('user_profile', {});
            LocalStorageManager.setUserProfile({});
          }

          if (level) {
            state.level = level.id;
          }
        }

        state.isLoadingUser = false;
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.isLoadingUser = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload) {
          const { authenticationToken, authenticationTokenRefresh } = action.payload;

          if (authenticationToken) {
            state.authenticationToken = authenticationToken;
            Cookies.set('csrftoken', authenticationToken);
            LocalStorageManager.setAuthenticationToken(authenticationToken);
          }

          if (authenticationTokenRefresh) {
            state.authenticationTokenRefresh = authenticationTokenRefresh;
            Cookies.set('csrftoken_refresh', authenticationTokenRefresh);
            LocalStorageManager.setAuthenticationTokenRefresh(authenticationTokenRefresh);
          }
        }

        state.isLoggingIn = false;
      })
      .addCase(login.rejected, (state) => {
        state.isLoggingIn = false;
      })
      .addCase(recoverUserPasswordThunk.pending, (state) => {
        state.isLoadingPasswordRecover = true;
      })
      .addCase(recoverUserPasswordThunk.fulfilled, (state) => {
        state.isLoadingPasswordRecover = false;
      })
      .addCase(recoverUserPasswordThunk.rejected, (state) => {
        state.isLoadingPasswordRecover = false;
      })
      .addCase(setUserDetails.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(setUserDetails.fulfilled, (state, action) => {
        if (action?.payload) {
          const { firstName, lastName } = action.payload;

          if (firstName && lastName) {
            const profile = {
              ...state.userProfile,
              ...action.payload
            };

            state.userProfile = profile;
            Cookies.set('user_profile', JSON.stringify(profile));
            LocalStorageManager.setUserProfile(profile);
          }
        }

        state.isLoadingUser = false;
      })
      .addCase(setUserDetails.rejected, (state) => {
        state.isLoadingUser = false;
      })
      .addCase(setUserPassword.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(setUserPassword.fulfilled, (state) => {
        state.isLoadingUser = false;
      })
      .addCase(setUserPassword.rejected, (state) => {
        state.isLoadingUser = false;
      });
  }
});

export const { initialize, logout, updateAuthenticationToken } = user.actions;

// Selectors
export const selectAuthenticationToken = state => state.user.authenticationToken;
export const selectInitialized = state => state.user.initialized;
export const selectLoginImage = state => state.user.loginImage;
export const selectLoginImageIsLoading = state => state.user.isLoadingLoginImage;
export const selectUserIsLoading = state => state.user.isLoadingUser;
export const selectUserIsLoadingPasswordRecover = state => state.user.isLoadingPasswordRecover;
export const selectUserIsLoggingIn = state => state.user.isLoggingIn;
export const selectUserLevel = state => state.user.level;
export const selectUserProfile = state => state.user.userProfile;

export default user.reducer;
