import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectClassComments } from 'store/slices/classes';

// Icons
import starIcon from 'assets/icons/star.svg';
import starOutlineIcon from 'assets/icons/star-outline.svg';

// Components
import BaseButton from 'components/BaseButton';
import BaseInput from 'components/BaseInput';
import Card from 'components/Card';
import Modal from 'components/Modal';


const NUMBER_OF_STARS = 5;

const ClassRatingAndComment = ({
  className,
  comment,
  state,
  rating,
  onSubmit
}) => {
  const comments = useSelector(selectClassComments);

  // State
  const [currentRating, setCurrentRating] = useState(0);
  const [selectedRating, setSelectedRating] = useState('');

  const { formState: { errors }, control, register, reset, setValue, watch } = useForm({
    defaultValues: {
      comment: '',
      rate: ''
    },
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    if (selectedRating) setValue('rate', selectedRating);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRating]);

  const isFormValid = () => {
    const inputs = ['comment', 'rate'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const handleModalConfirmation = () => {
    onSubmit(watch('comment'), selectedRating);
    reset();
  };

  const handleStarClick = (newValue) => {
    setSelectedRating(newValue);
  };

  const handleStarMouseEnter = (index) => {
    if (!rating) {
      setCurrentRating(index);
    }
  };

  const handleStarMouseLeave = () => {
    if (!rating) {
      setCurrentRating(0);
    }
  };

  const isValid = isFormValid();

  const submitButton = (
    <BaseButton
      className="w-full"
      disabled={!isValid}
      text="Enviar"
    />
  );

  const stars = [];

  for (let index = 1; index <= NUMBER_OF_STARS; index += 1) {
    stars.push((
      <div
        key={index}
        className={`inline pr-1 last-of-type:mr-0${rating ? ' pointer-events-none' : ''}`}
        role="button"
        tabIndex={0}
        onClick={() => handleStarClick(index)}
        onKeyPress={() => handleStarClick(index)}
        onMouseEnter={() => handleStarMouseEnter(index)}
        onMouseLeave={() => handleStarMouseLeave(index)}
      >
        <img
          alt="Avaliação"
          src={rating >= index || selectedRating >= index || currentRating >= index ? starIcon : starOutlineIcon}
        />
      </div>
    ));
  }

  return (
    <Card className={className ? className : ''}>
      <>
        <h5 className="text-center">
          {!rating && !comment ?
            (
              'Avaliações e Comentários'
            ) :
            (
              'Obrigado pelo seu Feedback!'
            )}
        </h5>
        {Array.isArray(comments) && !!comments.length > 0 ?
          comments.map((entry, index) => {
            const commentStars = [];

            for (let i = 1; i <= 5; i += 1) {
              commentStars.push((
                <img
                  key={i}
                  alt="Avaliação"
                  className="inline pr-1 last-of-type:mr-0 h-5"
                  src={entry.rating >= i ? starIcon : starOutlineIcon}
                />
              ));
            }

            return (
              <div
                key={index}
                className="py-5 border-b border-gray last-of-type:border-0 last-of-type:pb-0"
              >
                <div className="flex justify-between mb-2">
                  <p className="font-semibold text-light-blue">
                    {entry.username}
                  </p>
                  <div>
                    {commentStars}
                  </div>
                </div>
                <p className="text-dark-blue">
                  {entry.comment}
                </p>
              </div>
            );
          }) :
          (
            <p className="mt-5 text-center">
              Ainda não existem comentários.
            </p>
          )}
        {state !== null && !comment && !rating && (
          <form
            className={`${className ? ` ${className}` : ''}`}
          >
            <hr className="h-1 border-top border-border-gray mb-10" />
            <h5 className="text-center mb-5">
              Dê-nos os seu Feedback
            </h5>
            <Controller
              control={control}
              name="rate"
              render={({ field: onChange, value }) => (
                <>
                  <input
                    type="hidden"
                    value={value}
                    onChange={onChange}
                    {...register('rate')}
                  />
                  <div className="flex items-center justify-center mb-5">
                    {stars}
                  </div>
                  {errors.rate?.message && (
                    <div className="flex items-center justify-center mb-5">
                      <span className="text-xs text-error">
                        {errors.rate.message}
                      </span>
                    </div>
                  )}
                </>
              )}
              rules={{
                required: 'Insira uma avaliação.'
              }}
            />
            <Controller
              control={control}
              name="comment"
              render={({ field: onChange, value }) => (
                <BaseInput
                  error={errors.comment?.message ? 'Insira um comentário.' : ''}
                  placeholder="Escreva um comentário"
                  type="text"
                  value={value}
                  onChange={onChange}
                  {...register('comment')}
                />
              )}
              rules={{
                required: 'Insira um comentário.'
              }}
            />
            <Modal
              description="Confirma a sua submissão?"
              title="Confirmação"
              trigger={submitButton}
              onConfirmation={handleModalConfirmation}
            />
          </form>
        )}
      </>
    </Card>
  );
};

ClassRatingAndComment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.string,
  onSubmit: PropTypes.func,
  rating: PropTypes.number,
  state: PropTypes.string
};

ClassRatingAndComment.defaultProps = {
  className: null,
  comment: null,
  onSubmit: () => {},
  rating: null,
  state: null
};


export default ClassRatingAndComment;
