import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';

// Utils
import getYoutubeVideoId from 'utils/GetYoutubeVideoId';

// Icons
import { ReactComponent as FullScreen } from 'assets/icons/full-screen.svg';
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { ReactComponent as Pause } from 'assets/icons/pause.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';


const Video = ({
  className,
  image,
  playIsAvailable,
  videoLink,
  onEnd
}) => {
  // State
  const [videoElement, setVideoElement] = useState(null);
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const [videoIsFullScreen, setVideoIsFullScreen] = useState(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoOrigin, setVideoOrigin] = useState(null);

  useEffect(() => {
    if (videoLink) {
      if (videoLink.includes('vimeo')) setVideoOrigin('vimeo');
      else if (videoLink.includes('youtube')) setVideoOrigin('youtube');
    }
  }, [videoLink]);

  useEffect(() => {
    if (videoIsPlaying) {
      if (videoOrigin === 'vimeo') videoElement?.play();
      if (videoOrigin === 'youtube') videoElement?.target?.playVideo();
    } else {
      if (videoOrigin === 'vimeo') videoElement?.pause();
      if (videoOrigin === 'youtube') videoElement?.target?.pauseVideo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIsPlaying]);

  const handleFullScreenButtonClick = () => {
    setVideoIsFullScreen(prevState => !prevState);
  };

  const handlePlayPauseVideoButtonClick = () => {
    setVideoIsPlaying(prevState => !prevState);
  };

  const handleStartVideoButtonClick = () => {
    if (playIsAvailable) {
      if (image) {
        window.open(videoLink, '_blank');
        onEnd();
      } else {
        setVideoHasStarted(true);
        setVideoIsPlaying(true);

        if (videoOrigin === 'vimeo') videoElement?.play();
        if (videoOrigin === 'youtube') videoElement?.target?.playVideo();
      }
    }
  };

  const handleVideoReady = (event) => {
    if (videoOrigin === 'vimeo') event?.element?.classList.add('relative', 'rounded-lg', 'aspect-video', 'h-full', 'w-full', 'pointer-events-none');
    setVideoElement(event);
  };

  const preventScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (videoIsFullScreen) {
      document.body.addEventListener('wheel', preventScroll, { passive: false });
      document.body.addEventListener('touchmove', preventScroll, { passive: false });
      document.body.classList.add('bg-black');
    } else {
      document.body.classList.remove('bg-black');
      document.body.removeEventListener('wheel', preventScroll, { passive: false });
      document.body.removeEventListener('touchmove', preventScroll, { passive: false });
    }

    return () => {
      document.body.removeEventListener('wheel', preventScroll, { passive: false });
      document.body.removeEventListener('touchmove', preventScroll, { passive: false });
    };
  }, [videoIsFullScreen]);

  if (!videoLink) return null;

  const CurrentPlayPauseIcon = videoIsPlaying ? Pause : Play;

  return (
    <div className={`aspect-video${videoIsFullScreen ? ' fixed top-0 left-0 h-full w-full z-50 bg-black' : ' relative rounded-lg my-5 w-full '}${className ? ` ${className}` : ''}`}>
      {image ?
        (
          <div className="relative flex items-center justify-center h-full w-full rounded-lg pointer-events-none overflow-hidden">
            <img
              alt="Aula ao vivo"
              src={image}
            />
          </div>
        ) :
        (
          <>
            {videoOrigin === 'youtube' && (
              <YouTube
                className={`h-full w-full rounded-lg aspect-video pointer-events-none${videoIsFullScreen ? ' absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2' : ' relative'}`}
                controls={false}
                disableKeyboard
                height="100%"
                playsInline
                showRelatedVideos={false}
                video={getYoutubeVideoId(videoLink)}
                width="100%"
                onEnd={onEnd}
                onReady={handleVideoReady}
              />
            )}
            {videoOrigin === 'vimeo' && (
              <Vimeo
                className={`h-full w-full${videoIsFullScreen ? ' absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2' : ''}`}
                controls={false}
                height="100%"
                keyboard={false}
                video={videoLink}
                width="100%"
                onEnd={onEnd}
                onReady={handleVideoReady}
              />
            )}
          </>
        )}
      {videoHasStarted && (
        <>
          <CurrentPlayPauseIcon
            className="cursor-pointer absolute bottom-2 left-2 md:bottom-5 md:left-5 h-10 w-10 z-50"
            onClick={handlePlayPauseVideoButtonClick}
          />
          <FullScreen
            className="cursor-pointer absolute bottom-2 right-2 md:bottom-5 md:right-5 h-10 w-10 z-50"
            onClick={handleFullScreenButtonClick}
          />
        </>
      )}
      {!videoHasStarted && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center rounded-lg bg-light-blue/70">
          {(videoElement || image) ?
            (
              <Play
                className={`cursor-pointer h-20 w-20 hover:transform hover:scale-125 ease-in-out duration-300${!playIsAvailable ? ' pointer-events-none' : ''}`}
                onClick={handleStartVideoButtonClick}
              />
            ) :
            (
              <Loading className="animate-spin h-5 w-5" />
            )}
        </div>
      )}
    </div>
  );
};

Video.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  onEnd: PropTypes.func,
  playIsAvailable: PropTypes.bool,
  videoLink: PropTypes.string
};

Video.defaultProps = {
  className: null,
  image: null,
  onEnd: () => {},
  playIsAvailable: false,
  videoLink: null
};


export default Video;
