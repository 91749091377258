import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Reakit
import { Button } from 'reakit/Button';

// Icons
import { ReactComponent as Loading } from 'assets/icons/loading.svg';


const BaseButton = forwardRef(({
  backgroundColor,
  className,
  icon,
  loading,
  selected,
  secondary,
  text,
  ...props
}, ref) => {
  const classes = `base-button disabled:opacity-30 border-none${
    className ? ` ${className}` : ''}${
    backgroundColor === 'dark-blue' ? ' bg-dark-blue hover:bg-darker-blue' : ' bg-light-blue hover:bg-blue'}${
    loading ? ' inline-flex items-center' : ''}`;

  // eslint-disable-next-line max-len
  const secondaryClasses = `base-button disabled:opacity-30 text-light-blue${
    className ? ` ${className}` : ''}${
    selected ? ' bg-light-blue text-white border-light-blue hover:bg-light-blue hover:border-light-blue' : ' bg-transparent border border-light-blue hover:bg-light-blue hover:text-white hover:border-light-blue'}`;

  return (
    <Button
      ref={ref}
      className={secondary ? secondaryClasses : classes}
      {...props}
    >
      {loading && (
        <Loading className="animate-spin h-5 w-5 mr-2" />
      )}
      {!!text && text}
      {!!icon && (
        <div className={`flex items-center h-3 w-3${text ? ' ml-2' : ''}`}>
          {icon}
        </div>
      )}
    </Button>
  );
});

BaseButton.propTypes = {
  backgroundColor: PropTypes.oneOf(['dark-blue', 'light-blue']),
  className: PropTypes.string,
  icon: PropTypes.element,
  loading: PropTypes.bool,
  secondary: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string
};

BaseButton.defaultProps = {
  backgroundColor: 'light-blue',
  className: null,
  icon: null,
  loading: false,
  secondary: false,
  selected: false,
  text: null
};

BaseButton.displayName = 'BaseButton';

export default BaseButton;
