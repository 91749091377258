import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';
import PasswordChangeForm from 'components/PasswordChangeForm';
import ProfileForm from 'components/ProfileForm';


const UserProfile = ({
  className
}) => (
  <div className={`flex items-center justify-center ${className ? ` ${className}` : ''}`}>
    <div className="container">
      <div className="max-w-md mx-auto">
        <h2 className="mb-10">
          Dados Pessoais
        </h2>
        <Card>
          <ProfileForm />
        </Card>
        <h2 className="my-10">
          Alterar Password
        </h2>
        <Card className="max-w-md mx-auto">
          <PasswordChangeForm />
        </Card>
      </div>
    </div>
  </div>
);

UserProfile.propTypes = {
  className: PropTypes.string
};

UserProfile.defaultProps = {
  className: null
};


export default UserProfile;
