/* eslint-disable camelcase */
import { axiosGet } from 'api';
import {
  DASHBOARD
} from 'api/endpoints';


const fetchDashboard = () => axiosGet(DASHBOARD);

export default fetchDashboard;
