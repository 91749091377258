import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchNews from 'api/news';


const formatNews = (items) => {
  const formattedItem = items.map((entry) => {
    let date = null;

    if (entry.date_added) {
      date = new Date(entry.date_added).toLocaleDateString('pt-PT');
    }

    return ({
      ...entry,
      date,
      displayOrder: entry.display_order ?? null,
      isActive: entry.is_active ?? null,
      image: entry.image ?? null,
      lastUpdated: entry.last_updated ?? null,
      videoLink: entry.video_link ?? null
    });
  });

  return formattedItem;
};

// Thunks
export const getNews = createAsyncThunk(
  'news/getNews',
  pageNumber => fetchNews(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatNews(response.results);
      }

      return {
        newsItems: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const news = createSlice({
  name: 'news',
  initialState: {
    allNews: null,
    allNewsPages: null,
    initialized: false,
    isLoadingAllNews: false,
    isLoadingIndividualNews: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.isLoadingAllNews = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        if (action.payload) {
          const { newsItems, pages } = action.payload;

          if (newsItems) state.allNews = newsItems;
          if (pages) {
            state.allNewsPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingAllNews = false;
      })
      .addCase(getNews.rejected, (state) => {
        state.isLoadingAllNews = false;
      });
  }
});

// Selectors
export const selectAllNews = state => state.news.allNews;
export const selectAllNewsPages = state => state.news.allNewsPages;
export const selectIndivualNewsIsLoading = state => state.news.isLoadingIndividualNews;
export const selectNewsAreLoading = state => state.news.isLoadingAllNews;

export default news.reducer;
