import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchChallenges, fetchClassComments, fetchClasses, fetchLiveClasses, updateClass } from 'api/classes';

// Utils
import capitalizeFirstLetter from 'utils/CapitalizeFirstLetter';
import formatDuration from 'utils/FormatDuration';


const formatClasses = (items) => {
  const formattedItem = items.map((entry) => {
    let month = null;

    if (entry.active_month) {
      const date = new Date(entry.active_month);

      month = capitalizeFirstLetter(date.toLocaleString('pt-pt', { month: 'long' }));
    }

    return ({
      ...entry,
      activeMonth: month,
      challengeType: entry.challenge_type ?? null,
      dateAdded: entry.date_added ?? null,
      displayOrder: entry.display_order ?? null,
      duration: entry.duration ? formatDuration(entry.duration) : null,
      isActive: entry.is_active ?? null,
      isSpecialEvaluation: entry.is_special_evaluation ?? null,
      lastUpdated: entry.last_updated ?? null,
      liveEndDate: entry.live_end_date ?? null,
      liveStartDate: entry.live_start_date ?? null,
      pointsGiven: entry.points_given ?? null,
      videoLink: entry.video_link ?? null
    });
  });

  return formattedItem;
};

// Thunks
export const changeClass = createAsyncThunk(
  'classes/changeClass',
  ({
    challenge,
    comment,
    points,
    rating,
    state,
    username,
    video
  }) => updateClass({
    challenge,
    comment,
    points,
    rating,
    state,
    username,
    video
  })
    .then((response) => {
      let payload = null;

      if (response) {
        payload = response;
      }

      return payload;
    })
);

export const getChallenges = createAsyncThunk(
  'classes/getChallenges',
  pageNumber => fetchChallenges(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatClasses(response.results);
      }

      return {
        challenges: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const getClassComments = createAsyncThunk(
  'classes/getClassComments',
  id => fetchClassComments(id)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = response.results;
      }

      return payload;
    })
);

export const getClasses = createAsyncThunk(
  'classes/getClasses',
  pageNumber => fetchClasses(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatClasses(response.results);
      }

      return {
        classesItems: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const getLiveClasses = createAsyncThunk(
  'classes/getLiveClasses',
  () => fetchLiveClasses()
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatClasses(response.results);
      }

      return {
        liveClassesItems: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const submitVideo = createAsyncThunk(
  'classes/submitVideo',
  data => updateClass({ video: data })
    .then((response) => {
      let payload = null;

      if (response) {
        payload = response;
      }

      return payload;
    })
);

export const classes = createSlice({
  name: 'classes',
  initialState: {
    allChallenges: null,
    allClasses: null,
    allLiveClasses: null,
    challengesPages: null,
    classComments: null,
    classSubmission: 0,
    classesPages: null,
    initialized: false,
    isLoadingClass: false,
    isLoadingClasses: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeClass.pending, (state) => {
        state.isLoadingClass = true;
      })
      .addCase(changeClass.fulfilled, (state, action) => {
        if (action.payload) {
          state.classSubmission = action.payload;
        }

        state.isLoadingClass = false;
      })
      .addCase(changeClass.rejected, (state) => {
        state.isLoadingClass = false;
      })
      .addCase(getChallenges.pending, (state) => {
        state.isLoadingClasses = true;
      })
      .addCase(getChallenges.fulfilled, (state, action) => {
        if (action.payload) {
          const { challenges, pages } = action.payload;

          if (challenges) state.allChallenges = challenges;
          if (pages) {
            state.challengesPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingClasses = false;
      })
      .addCase(getChallenges.rejected, (state) => {
        state.isLoadingClasses = false;
      })
      .addCase(getClassComments.pending, (state) => {
        state.isLoadingClass = true;
      })
      .addCase(getClassComments.fulfilled, (state, action) => {
        if (action.payload) {
          state.classComments = action.payload;
        }

        state.isLoadingClass = false;
      })
      .addCase(getClassComments.rejected, (state) => {
        state.isLoadingClass = false;
      })
      .addCase(getClasses.pending, (state) => {
        state.isLoadingClasses = true;
      })
      .addCase(getClasses.fulfilled, (state, action) => {
        if (action.payload) {
          const { classesItems, pages } = action.payload;

          if (classesItems) state.allClasses = classesItems;
          if (pages) {
            state.classesPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingClasses = false;
      })
      .addCase(getClasses.rejected, (state) => {
        state.isLoadingClasses = false;
      })
      .addCase(getLiveClasses.pending, (state) => {
        state.isLoadingClasses = true;
      })
      .addCase(getLiveClasses.fulfilled, (state, action) => {
        if (action.payload) {
          const { liveClassesItems, pages } = action.payload;

          if (liveClassesItems) state.allLiveClasses = liveClassesItems;
          if (pages) {
            state.classesPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingClasses = false;
      })
      .addCase(getLiveClasses.rejected, (state) => {
        state.isLoadingClasses = false;
      })
      .addCase(submitVideo.pending, (state) => {
        state.isLoadingClass = true;
      })
      .addCase(submitVideo.fulfilled, (state, action) => {
        if (action.payload) {
          state.classSubmission = action.payload;
        }

        state.isLoadingClass = false;
      })
      .addCase(submitVideo.rejected, (state) => {
        state.isLoadingClass = false;
      });
  }
});

// Selectors
export const selectAllChallenges = state => state.classes.allChallenges;
export const selectAllClasses = state => state.classes.allClasses;
export const selectAllLiveClasses = state => state.classes.allLiveClasses;
export const selectChallengesPages = state => state.classes.challengesPages;
export const selectClassesAreLoading = state => state.classes.isLoadingClasses;
export const selectClassIsLoading = state => state.classes.isLoadingClass;
export const selectClassComments = state => state.classes.classComments;
export const selectClassSubmission = state => state.classes.classSubmission;
export const selectClassesPages = state => state.classes.classesPages;

export default classes.reducer;
