const menuItems = [
  {
    path: '/dashboard',
    text: 'Dashboard',
    textColor: 'white'
  },
  {
    path: '/como-funciona',
    text: 'Como Funciona',
    textColor: 'white'
  },
  {
    path: '/aulas',
    text: 'Aulas',
    textColor: 'white'
  },
  {
    path: '/desafios',
    text: 'Desafios',
    textColor: 'white'
  },
  {
    path: '/ranking',
    text: 'Ranking',
    textColor: 'white'
  },
  {
    path: '/novidades',
    text: 'Dicas e Novidades',
    textColor: 'white'
  },
  {
    items: [
      {
        path: '/perfil',
        text: 'Perfil',
        textColor: 'light-blue'
      },
      {
        path: '/movimentos',
        text: 'Movimentos',
        textColor: 'light-blue'
      },
      {
        action: 'logout',
        path: '/',
        showWithoutUserProfile: true,
        text: 'Logout',
        textColor: 'light-blue'
      }
    ],
    showWithoutUserProfile: true,
    text: 'Conta'
  },
  {
    path: '/loja',
    text: 'Loja',
    textColor: 'white'
  }
];

const mobileMenuItems = [
  {
    path: '/loja',
    text: 'Loja',
    textColor: 'white'
  },
  {
    path: '/dashboard',
    text: 'Dashboard',
    textColor: 'white'
  },
  {
    path: '/como-funciona',
    text: 'Como Funciona',
    textColor: 'white'
  },
  {
    path: '/aulas',
    text: 'Aulas',
    textColor: 'white'
  },
  {
    path: '/desafios',
    text: 'Desafios',
    textColor: 'white'
  },
  {
    path: '/ranking',
    text: 'Ranking',
    textColor: 'white'
  },
  {
    path: '/novidades',
    text: 'Dicas e Novidades',
    textColor: 'white'
  },
  {
    path: '/perfil',
    text: 'Perfil',
    textColor: 'white'
  },
  {
    path: '/movimentos',
    text: 'Movimentos',
    textColor: 'white'
  },
  {
    action: 'logout',
    path: '/',
    showWithoutUserProfile: true,
    text: 'Logout',
    textColor: 'white'
  }
];

export {
  menuItems,
  mobileMenuItems
};
