import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getChallenges, selectAllChallenges, selectChallengesPages, selectClassesAreLoading } from 'store/slices/classes';
import { getUserDetails, selectUserLevel } from 'store/slices/user';
import { useSearchParams } from 'react-router-dom';

// Components
import ClassesSection from 'components/ClassesSection';
import FullScreenLoader from 'components/FullScreenLoader';
import Pagination from 'components/Pagination';
import UserInfo from 'components/UserInfo';


const Challenges = ({
  className,
  link,
  title
}) => {
  const dispatch = useDispatch();
  const classesAreLoading = useSelector(selectClassesAreLoading);
  const challenges = useSelector(selectAllChallenges);
  const challengesPages = useSelector(selectChallengesPages);
  const userLevel = useSelector(selectUserLevel);

  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [params, setParams] = useSearchParams();

  const pageNumber = params.get('page');

  const handleNextPageClick = () => {
    dispatch(getChallenges(nextPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os desafios.');

        return response;
      })
      .then((response) => {
        setParams({ page: nextPageNumber });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handlePreviousPageClick = () => {
    dispatch(getChallenges(previousPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os desafios.');

        return response;
      })
      .then((response) => {
        if (previousPageNumber) setParams({ page: previousPageNumber });
        else params.delete('page');
        setParams(params);

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (challengesPages) {
      if (challengesPages.next) {
        const nextNumber = challengesPages.next.split('page=');

        if (nextNumber[1]) setNextPageNumber(nextNumber[1]);
      }

      if (challengesPages.previous) {
        const previousNumber = challengesPages.previous.split('page=');

        if (previousNumber[1]) setPreviousPageNumber(previousNumber[1]);
      }
    }
  }, [challengesPages]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(getChallenges(pageNumber))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os desafios.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      dispatch(getChallenges())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os desafios.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getUserDetails())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os detalhes do utilizador.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  if (!Array.isArray(challenges)) return null;

  let itemsToShowForCurrentUserLevel = challenges.filter(item => item.level[0].includes(userLevel));

  const challengesArrayCopy = [...itemsToShowForCurrentUserLevel];

  if (Array.isArray(challengesArrayCopy)) {
    challengesArrayCopy.forEach((entry) => {
      if (entry.isSpecialEvaluation) challengesArrayCopy.splice(challengesArrayCopy.indexOf(entry), 1);
    });
  }

  itemsToShowForCurrentUserLevel = challengesArrayCopy;

  return classesAreLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <UserInfo />
        <ClassesSection
          className="mt-10"
          items={itemsToShowForCurrentUserLevel}
          link={link}
          showAll
          title={title}
        />
        {!!challengesPages && (
          <Pagination
            className="mt-10"
            nextPage={challengesPages.next}
            previousPage={challengesPages.previous}
            onNextPageClick={handleNextPageClick}
            onPreviousPageClick={handlePreviousPageClick}
          />
        )}
      </div>
    );
};

Challenges.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string
};

Challenges.defaultProps = {
  className: null,
  title: null
};


export default Challenges;
