import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseLink from './BaseLink';
import CardWithNumber from './CardWithNumber';


const UserStatsCards = ({
  cards,
  className,
  link
}) => {
  if (!Array.isArray(cards) || !cards.length) return null;

  return (
    <div className={className ? className : ''}>
      <div className="flex flex-col md:flex-row gap-4 flex-wrap">
        {cards.map((card, index) => (
          <CardWithNumber
            key={index}
            className="flex-1"
            color={card.color}
            icon={card.icon}
            items={card.items}
            title={card.title}
          />
        ))}
      </div>
      {!!link && (
        <div className="flex justify-end mt-8">
          <BaseLink
            arrowPosition="right"
            hasArrow
            path={link.path}
            text={link.text}
          />
        </div>
      )}
    </div>
  );
};

UserStatsCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf(['blue', 'orange']),
    items: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.oneOf(['blue', 'orange']),
      label: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    title: PropTypes.string
  })),
  className: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string,
    text: PropTypes.string
  })
};

UserStatsCards.defaultProps = {
  cards: null,
  className: null,
  link: null
};


export default UserStatsCards;
