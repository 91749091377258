/* eslint-disable camelcase */
import { axiosGet } from 'api';
import {
  RANK_GLOBAL,
  RANK_MONTH,
  RANK_SUMMARY,
  RANK_USER_GLOBAL,
  RANK_USER_MONTH,
  RANK_USER_SUMMARY
} from 'api/endpoints';


const fetchGlobalRanking = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${RANK_GLOBAL}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(RANK_GLOBAL);
};

const fetchMonthlyRanking = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${RANK_MONTH}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(RANK_MONTH);
};

const fetchSummaryRanking = () => axiosGet(RANK_SUMMARY);

const fetchUserGlobalRanking = () => axiosGet(RANK_USER_GLOBAL);

const fetchUserMonthRanking = () => axiosGet(RANK_USER_MONTH);

const fetchUserSummaryRanking = () => axiosGet(RANK_USER_SUMMARY);


export {
  fetchGlobalRanking,
  fetchMonthlyRanking,
  fetchSummaryRanking,
  fetchUserGlobalRanking,
  fetchUserMonthRanking,
  fetchUserSummaryRanking
};
