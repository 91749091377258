import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getPointsTransactions, selectAllPointsTransactions, selectPointsTransactionsPages, selectTransactionsIsLoading } from 'store/slices/marketplace';
import { selectUserProfile } from 'store/slices/user';
import { useSearchParams } from 'react-router-dom';

// Components
import FullScreenLoader from 'components/FullScreenLoader';
import Pagination from 'components/Pagination';
import Transactions from 'components/Transactions';
import UserStatsCards from 'components/UserStatsCards';


const News = ({
  className
}) => {
  const dispatch = useDispatch();
  const isLoadingTransactions = useSelector(selectTransactionsIsLoading);
  const pointsTransactions = useSelector(selectAllPointsTransactions);
  const pointsTransactionsPages = useSelector(selectPointsTransactionsPages);
  const user = useSelector(selectUserProfile);

  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [params, setParams] = useSearchParams();

  const pageNumber = params.get('page');

  const handleNextPageClick = () => {
    dispatch(getPointsTransactions(nextPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os movimentos.');

        return response;
      })
      .then((response) => {
        setParams({ page: nextPageNumber });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handlePreviousPageClick = () => {
    dispatch(getPointsTransactions(previousPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os movimentos.');

        return response;
      })
      .then((response) => {
        if (previousPageNumber) setParams({ page: previousPageNumber });
        else params.delete('page');
        setParams(params);

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (pointsTransactionsPages) {
      if (pointsTransactionsPages.next) {
        const nextNumber = pointsTransactionsPages.next.split('page=');

        if (nextNumber[1]) setNextPageNumber(nextNumber[1]);
      }

      if (pointsTransactionsPages.previous) {
        const previousNumber = pointsTransactionsPages.previous.split('page=');

        if (previousNumber[1]) setPreviousPageNumber(previousNumber[1]);
      }
    }
  }, [pointsTransactionsPages]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(getPointsTransactions(pageNumber))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os movimentos.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      dispatch(getPointsTransactions())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os movimentos.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoadingTransactions ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <h2 className="mb-10">
          Histórico de Movimentos
        </h2>
        <UserStatsCards
          cards={[
            {
              color: 'blue',
              icon: 'star-circle',
              items: [
                {
                  color: 'blue',
                  number: user?.points
                }
              ],
              title: 'Pontos disponíveis'
            }
          ]}
          className="mb-10 w-full"
        />
        <hr className="h-1 border-top border-border-gray my-10" />
        {Array.isArray(pointsTransactions) && !!pointsTransactions.length && !isLoadingTransactions ?
          (
            <Transactions transactions={pointsTransactions} />
          ) :
          (
            <p>
              Ainda não efetuou qualquer movimento.
            </p>
          )}
        {!!pointsTransactionsPages && (
          <Pagination
            className="mt-10"
            nextPage={pointsTransactionsPages.next}
            previousPage={pointsTransactionsPages.previous}
            onNextPageClick={handleNextPageClick}
            onPreviousPageClick={handlePreviousPageClick}
          />
        )}
      </div>
    );
};

News.propTypes = {
  className: PropTypes.string
};

News.defaultProps = {
  className: null
};


export default News;
