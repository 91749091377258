// Keys for interacting with LocalStorage:
const API_BASE_URL = 'api_base_url';
const AUTHENTICATION_TOKEN = 'auth_token';
const AUTHENTICATION_TOKEN_REFRESH = 'auth_token_refresh';
const AUTHENTICATION_TOKEN_EXPIRATION_TIME = 'auth_token_exp';
const USER_PROFILE = 'profile';

export default class LocalStorageManager {
  // API: Base URL

  static setApiBaseUrl (apiBaseUrl) {
    localStorage.setItem(API_BASE_URL, apiBaseUrl);
  }

  static getApiBaseUrl () {
    const value = localStorage.getItem(API_BASE_URL);

    if (value === 'null') return null;

    return value;
  }

  static removeApiBaseUrl () {
    localStorage.removeItem(API_BASE_URL);
  }

  // Authentication: Token

  static setAuthenticationToken (authenticationToken) {
    localStorage.setItem(AUTHENTICATION_TOKEN, authenticationToken);
  }

  static getAuthenticationToken () {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(AUTHENTICATION_TOKEN);

      if (value === 'null') return null;

      return value;
    }

    return null;
  }

  static removeAuthenticationToken () {
    localStorage.removeItem(AUTHENTICATION_TOKEN);
  }

  // Authentication: Token Refresh

  static setAuthenticationTokenRefresh (authenticationTokenRefresh) {
    localStorage.setItem(AUTHENTICATION_TOKEN_REFRESH, authenticationTokenRefresh);
  }

  static getAuthenticationTokenRefresh () {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(AUTHENTICATION_TOKEN_REFRESH);

      if (value === 'null') return null;

      return value;
    }

    return null;
  }

  static removeAuthenticationTokenRefresh () {
    localStorage.removeItem(AUTHENTICATION_TOKEN_REFRESH);
  }

  // Authentication: Token expiration time

  static setAuthenticationTokenExpirationTime (tokenExpirationTime) {
    localStorage.setItem(AUTHENTICATION_TOKEN_EXPIRATION_TIME, tokenExpirationTime);
  }

  static getAuthenticationTokenExpirationTime () {
    return localStorage.getItem(AUTHENTICATION_TOKEN_EXPIRATION_TIME);
  }

  static removeAuthenticationTokenExpirationTime () {
    localStorage.removeItem(AUTHENTICATION_TOKEN_EXPIRATION_TIME);
  }

  // User profile

  static setUserProfile (value) {
    const stringified = JSON.stringify(value);

    localStorage.setItem(USER_PROFILE, stringified);
  }

  static getUserProfile () {
    const stored = localStorage.getItem(USER_PROFILE);
    const parsed = JSON.parse(stored);

    return parsed;
  }

  static removeUserProfile () {
    localStorage.removeItem(USER_PROFILE);
  }
}
