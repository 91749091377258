import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Reakit
import { Input } from 'reakit/Input';

// Components
import BaseLink from 'components/BaseLink';


const BaseInput = forwardRef(({
  className,
  disabled,
  onChange,
  onIconClick,
  error,
  icon,
  inputClassName,
  label,
  link,
  placeholder,
  type,
  ...props
}, ref) => {
  const inputClasses = `input bg-background-gray my-2 w-full focus:shadow-input${
    disabled ? ' text-text-gray' : ''}${
    inputClassName ? ` ${inputClassName}` : ''}${
    error ? ' border-solid border-2 border-error' : ''}`;
  const labelClasses = 'label p-0';
  const labelTextClasses = `label-text font-semibold text-light-blue${disabled ? ' opacity-50' : ''}`;

  return (
    <div className={`form-control w-full${className ? ` ${className}` : ''}`}>
      {!!label && (
        <label className={labelClasses}>
          <span className={labelTextClasses}>
            {label}
          </span>
        </label>
      )}
      {!!error && (
        <span className="text-xs text-error">
          {error}
        </span>
      )}
      <div className="relative">
        <Input
          ref={ref}
          className={inputClasses}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          {...props}
        />
        {icon && (
          <button
            type="button"
            onClick={onIconClick}
          >
            <img
              alt="Show or Hide Password"
              className="absolute h-5 w-5 top-1/2 -translate-y-1/2 right-4"
              src={icon}
            />
          </button>
        )}
      </div>
      {!!link && (
        <BaseLink
          className="font-normal text-xs ml-auto"
          path={link.path}
          text={link.text}
        />
      )}
    </div>
  );
});

BaseInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

BaseInput.defaultProps = {
  className: null,
  disabled: false,
  error: null,
  icon: null,
  inputClassName: null,
  label: null,
  link: null,
  onChange: () => {},
  onIconClick: () => {},
  placeholder: null,
  type: 'text'
};

BaseInput.displayName = 'BaseInput';

export default BaseInput;
