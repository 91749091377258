import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getNews, selectAllNews, selectAllNewsPages, selectNewsAreLoading } from 'store/slices/news';
import { useSearchParams } from 'react-router-dom';

// Components
import NewsSection from 'components/NewsSection';
import FullScreenLoader from 'components/FullScreenLoader';
import Pagination from 'components/Pagination';


const News = ({
  className,
  link,
  title
}) => {
  const dispatch = useDispatch();
  const newsAreLoading = useSelector(selectNewsAreLoading);
  const news = useSelector(selectAllNews);
  const newsPages = useSelector(selectAllNewsPages);

  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [params, setParams] = useSearchParams();

  const pageNumber = params.get('page');

  const handleNextPageClick = () => {
    dispatch(getNews(nextPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as dicas e novidades.');

        return response;
      })
      .then((response) => {
        setParams({ page: nextPageNumber });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handlePreviousPageClick = () => {
    dispatch(getNews(previousPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as dicas e novidades.');

        return response;
      })
      .then((response) => {
        if (previousPageNumber) setParams({ page: previousPageNumber });
        else params.delete('page');
        setParams(params);

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (newsPages) {
      if (newsPages.next) {
        const nextNumber = newsPages.next.split('page=');

        if (nextNumber[1]) setNextPageNumber(nextNumber[1]);
      }

      if (newsPages.previous) {
        const previousNumber = newsPages.previous.split('page=');

        if (previousNumber[1]) setPreviousPageNumber(previousNumber[1]);
      }
    }
  }, [newsPages]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(getNews(pageNumber))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as dicas e novidades.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      dispatch(getNews())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as dicas e novidades.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((!Array.isArray(news) || !news.length)) return null;

  return newsAreLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <NewsSection
          items={news}
          link={link}
          showAll
          title={title}
        />
        {!!newsPages && (
          <Pagination
            className="mt-10"
            nextPage={newsPages.next}
            previousPage={newsPages.previous}
            onNextPageClick={handleNextPageClick}
            onPreviousPageClick={handlePreviousPageClick}
          />
        )}
      </div>
    );
};

News.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string
};

News.defaultProps = {
  className: null,
  title: null
};


export default News;
