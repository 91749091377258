const AUTHENTICATION_LOGIN = 'token/';
const AUTHENTICATION_RECOVER_PASSWORD = 'update_password?f=1';
const AUTHENTICATION_REFRESH = 'token/refresh/';
const AUTHENTICATION_SIGN_UP = 'signup/';
const AUTHENTICATION_UPDATE_PASSWORD = 'update_password';
const CHALLENGES = 'challenges/2';
const CHALLENGE_UPDATE = 'challenge_update';
const CLASSES = 'challenges/1';
const COMMENTS = 'comments/';
const DASHBOARD = 'dashboard';
const LIVE_CLASSES = 'challenges/3';
const LOCALITIES = 'localities';
const LOGIN_BANNER = 'login_banner';
const PERSONAL_DETAIL = 'personal_detail';
const POINTS_HISTORY = 'point_history';
const PRODUCTS = 'products';
const RANK_GLOBAL = 'rank/3';
const RANK_MONTH = 'rank/2';
const RANK_SUMMARY = 'rank/1';
const RANK_USER_GLOBAL = 'user_rank/3';
const RANK_USER_MONTH = 'user_rank/2';
const RANK_USER_SUMMARY = 'user_rank/1';
const TIPS = 'tips';
const TRANSACTIONS = 'transactions';

export {
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_RECOVER_PASSWORD,
  AUTHENTICATION_REFRESH,
  AUTHENTICATION_SIGN_UP,
  AUTHENTICATION_UPDATE_PASSWORD,
  CLASSES,
  COMMENTS,
  CHALLENGES,
  CHALLENGE_UPDATE,
  DASHBOARD,
  LIVE_CLASSES,
  LOCALITIES,
  LOGIN_BANNER,
  PERSONAL_DETAIL,
  POINTS_HISTORY,
  PRODUCTS,
  RANK_GLOBAL,
  RANK_MONTH,
  RANK_SUMMARY,
  RANK_USER_GLOBAL,
  RANK_USER_MONTH,
  RANK_USER_SUMMARY,
  TIPS,
  TRANSACTIONS
};
