import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { getUserDetails, selectUserProfile } from 'store/slices/user';
import { useDispatch, useSelector } from 'react-redux';

// Components
import BaseButton from 'components/BaseButton';
import MultiRangeSlider from 'components/MultiRangeSlider';
import UserStatsCards from 'components/UserStatsCards';
import ProductCard from 'components/ProductCard';


const MarketplaceSection = ({
  className,
  items,
  title
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserProfile);

  const [currentMaxValue, setCurrentMaxValue] = useState(null);
  const [currentMinValue, setCurrentMinValue] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [params, setParams] = useSearchParams();

  let staredProducts = null;
  let otherProducts = null;

  if (Array.isArray(items) && !!items.length) {
    staredProducts = items.filter(item => item.isStared);
    otherProducts = items.filter(item => !item.isStared);
  }

  useEffect(() => {
    const order = params.get('order');

    if (order === 'ascending') setFilteredProducts(otherProducts.sort((a, b) => a.points - b.points));
    if (order === 'descending') setFilteredProducts(otherProducts.sort((a, b) => b.points - a.points));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const staredItemsWithDisplayOrder = staredProducts.filter(item => item.displayOrder > 0);
  const staredItemsSortedByDisplayOrder = staredItemsWithDisplayOrder.sort((a, b) => {
    if (a.displayOrder < b.displayOrder) return -1;
    if (a.displayOrder > b.displayOrder) return 1;

    return 0;
  });

  const otherItemsWithDisplayOrder = otherProducts.filter(item => item.displayOrder > 0);
  const otherItemsSortedByDisplayOrder = otherItemsWithDisplayOrder.sort((a, b) => {
    if (a.displayOrder < b.displayOrder) return -1;
    if (a.displayOrder > b.displayOrder) return 1;

    return 0;
  });

  const allStaredItems = [...staredItemsSortedByDisplayOrder, ...staredProducts];
  const uniqueStaredItems = [...new Set(allStaredItems)];

  const allOtherItems = [...otherItemsSortedByDisplayOrder, ...otherProducts];
  const uniqueOtherItems = [...new Set(allOtherItems)];

  const higherPointsValue = Math.max(...otherProducts.map(product => product.points));
  const smallerPointsValue = Math.min(...otherProducts.map(product => product.points));

  const handleAscendentPointsProductsButtonClick = () => {
    setFilteredProducts(otherProducts.sort((a, b) => a.points - b.points));

    const currentOrderParam = params.get('order');

    if (currentOrderParam === 'ascending') {
      params.delete('order');
      setParams(params);
      setFilteredProducts(uniqueOtherItems);
    } else {
      setParams({
        order: 'ascending'
      });
    }
  };

  const handleDescendentPointsProductsButtonClick = () => {
    setFilteredProducts(otherProducts.sort((a, b) => b.points - a.points));

    const currentOrderParam = params.get('order');

    if (currentOrderParam === 'descending') {
      params.delete('order');
      setParams(params);
      setFilteredProducts(uniqueOtherItems);
    } else {
      setParams({
        order: 'descending'
      });
    }
  };

  const handleRangeSlideChange = (values) => {
    setCurrentMaxValue(values.max);
    setCurrentMinValue(values.min);
  };

  useEffect(() => {
    if (currentMaxValue && currentMinValue) {
      const productsToShow = otherProducts.filter(product => product.points >= currentMinValue && product.points <= currentMaxValue);
      const currentOrderParam = params.get('order');

      if (currentOrderParam === 'ascending') {
        setFilteredProducts(productsToShow.sort((a, b) => a.points - b.points));
      } else if (currentOrderParam === 'descending') {
        setFilteredProducts(productsToShow.sort((a, b) => b.points - a.points));
      } else {
        setFilteredProducts(productsToShow);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMaxValue, currentMinValue]);

  useEffect(() => {
    dispatch(getUserDetails())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os pontos.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  return (
    <div className={className ? className : ''}>
      {!!title && (
        <h2 className="mb-10">
          {title}
        </h2>
      )}
      <UserStatsCards
        cards={[
          {
            color: 'blue',
            icon: 'star-circle',
            items: [
              {
                color: 'blue',
                number: user?.points
              }
            ],
            title: 'Pontos disponíveis'
          }
        ]}
        className="mb-10 w-full"
        link={{
          path: '/movimentos',
          text: 'Movimentos'
        }}
      />
      {Array.isArray(uniqueStaredItems) && !!uniqueStaredItems.length && (
        <>
          <hr className="h-1 border-top border-border-gray my-10" />
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {uniqueStaredItems.map((item, index) => (
              <ProductCard
                key={index}
                id={item.id}
                imageLink={item.imageLink}
                isStared={item.isStared}
                name={item.name}
                points={item.points}
                stock={item.stock}
              />
            ))}
          </div>
          <hr className="h-1 border-top border-border-gray my-10" />
        </>
      )}
      <div className="flex flex-col md:flex-row md:justify-between gap-10 items-top my-10">
        <div>
          <span className="block text-xs mb-3">
            Ordenar por:
          </span>
          <BaseButton
            className="text-xs mr-2"
            secondary
            selected={params.get('order') === 'ascending'}
            text="Pontos: ascendente"
            onClick={handleAscendentPointsProductsButtonClick}
          />
          <BaseButton
            className="text-xs"
            secondary
            selected={params.get('order') === 'descending'}
            text="Pontos: descendente"
            onClick={handleDescendentPointsProductsButtonClick}
          />
        </div>
        <div>
          <span className="block text-xs mb-3">
            Intervalo de pontos:
          </span>
          <div className="inline-block mt-3 mb-10">
            <MultiRangeSlider
              max={higherPointsValue}
              min={smallerPointsValue}
              onChange={handleRangeSlideChange}
            />
          </div>
        </div>
      </div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {Array.isArray(filteredProducts) && filteredProducts.length && filteredProducts.map((item, index) => (
          <ProductCard
            key={index}
            id={item.id}
            imageLink={item.imageLink}
            isStared={item.isStared}
            name={item.name}
            points={item.points}
            stock={item.stock}
          />
        ))}
        {!filteredProducts && Array.isArray(uniqueOtherItems) && uniqueOtherItems.length && uniqueOtherItems.map((item, index) => (
          <ProductCard
            key={index}
            id={item.id}
            imageLink={item.imageLink}
            isStared={item.isStared}
            name={item.name}
            points={item.points}
            stock={item.stock}
          />
        ))}
      </div>
    </div>
  );
};

MarketplaceSection.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    isStared: PropTypes.bool,
    name: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired
  })).isRequired,
  title: PropTypes.string
};

MarketplaceSection.defaultProps = {
  className: null,
  title: null
};


export default MarketplaceSection;
