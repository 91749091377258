import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGlobalRanking, fetchMonthlyRanking, fetchSummaryRanking, fetchUserSummaryRanking } from 'api/rank';


const formatGlobalSummary = (items) => {
  /* eslint-disable camelcase */
  let globalRank = [];

  if (Array.isArray(items)) {
    globalRank = items.map(entry => ({
      ...entry,
      pointsRank: entry.points_rank,
      totalPoints: entry.total_points,
      userRecordId: entry.user_record_id
    }));
  }
  /* eslint-enable camelcase */

  return globalRank;
};

const formatMonthlySummary = (items) => {
  /* eslint-disable camelcase */
  let monthlyRank = [];

  if (Array.isArray(items)) {
    monthlyRank = items.map(entry => ({
      ...entry,
      pointsRank: entry.points_rank,
      totalPoints: entry.total_points,
      userRecordId: entry.user_record_id
    }));
  }
  /* eslint-enable camelcase */

  return monthlyRank;
};

const formatRankSummary = (items) => {
  /* eslint-disable camelcase */
  const { global_rank, global_user_rank, month_rank, month_user_rank } = items;

  let globalRank = null;
  let monthRank = null;
  let userGlobalRank = null;
  let userMonthlyRank = null;

  if (Array.isArray(global_rank)) {
    globalRank = global_rank.map(entry => ({
      ...entry,
      pointsRank: entry.points_rank,
      totalPoints: entry.total_points,
      userRecordId: entry.user_record_id
    }));
  }

  if (Array.isArray(month_rank)) {
    monthRank = month_rank.map(entry => ({
      ...entry,
      pointsRank: entry.points_rank,
      totalPoints: entry.total_points,
      userRecordId: entry.user_record_id
    }));
  }

  if (global_user_rank) {
    userGlobalRank = {
      ...global_user_rank,
      points: global_user_rank.points ?? 0,
      position: global_user_rank.position ?? '-'
    };
  }

  if (month_user_rank) {
    userMonthlyRank = {
      ...month_user_rank,
      points: month_user_rank.points ?? 0,
      position: month_user_rank.position ?? '-'
    };
  }
  /* eslint-enable camelcase */

  return {
    globalRank,
    monthRank,
    userGlobalRank,
    userMonthlyRank
  };
};

// Thunks
export const getGlobalRank = createAsyncThunk(
  'rank/getGlobalRank',
  pageNumber => fetchGlobalRanking(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatGlobalSummary(response.results);
      }

      return {
        ranks: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const getMonthlyRank = createAsyncThunk(
  'rank/getMonthlyRank',
  pageNumber => fetchMonthlyRanking(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatMonthlySummary(response.results);
      }

      return {
        ranks: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const getRankSummary = createAsyncThunk(
  'rank/getRankSummarys',
  () => fetchSummaryRanking()
    .then((response) => {
      let payload = null;

      if (response) {
        payload = formatRankSummary(response);
      }

      return payload;
    })
);

export const getUserRankSummary = createAsyncThunk(
  'rank/getUserRankSummarys',
  () => fetchUserSummaryRanking()
    .then((response) => {
      let payload = null;

      if (response) {
        payload = formatRankSummary(response);
      }

      return payload;
    })
);

export const rank = createSlice({
  name: 'rank',
  initialState: {
    initialized: false,
    isLoadingGlobalRanking: false,
    isLoadingMonthlyRanking: false,
    isLoadingRankingSummary: false,
    globalRank: null,
    globalRankPages: null,
    monthlyRank: null,
    monthlyRankPages: null,
    rankSummary: null,
    rankUserSummary: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalRank.pending, (state) => {
        state.isLoadingGlobalRanking = true;
      })
      .addCase(getGlobalRank.fulfilled, (state, action) => {
        if (action.payload) {
          const { ranks, pages } = action.payload;

          if (ranks) state.globalRank = ranks;
          if (pages) {
            state.globalRankPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingGlobalRanking = false;
      })
      .addCase(getGlobalRank.rejected, (state) => {
        state.isLoadingGlobalRanking = false;
      })
      .addCase(getMonthlyRank.pending, (state) => {
        state.isLoadingMonthlyRanking = true;
      })
      .addCase(getMonthlyRank.fulfilled, (state, action) => {
        if (action.payload) {
          const { ranks, pages } = action.payload;

          if (ranks) state.monthlyRank = ranks;
          if (pages) {
            state.monthlyRankPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingMonthlyRanking = false;
      })
      .addCase(getMonthlyRank.rejected, (state) => {
        state.isLoadingMonthlyRanking = false;
      })
      .addCase(getRankSummary.pending, (state) => {
        state.isLoadingRankingSummary = true;
      })
      .addCase(getRankSummary.fulfilled, (state, action) => {
        if (action.payload) {
          state.rankSummary = action.payload;
        }

        state.isLoadingRankingSummary = false;
      })
      .addCase(getRankSummary.rejected, (state) => {
        state.isLoadingRankingSummary = false;
      })
      .addCase(getUserRankSummary.pending, (state) => {
        state.isLoadingRankingSummary = true;
      })
      .addCase(getUserRankSummary.fulfilled, (state, action) => {
        if (action.payload) {
          state.rankUserSummary = action.payload;
        }

        state.isLoadingRankingSummary = false;
      })
      .addCase(getUserRankSummary.rejected, (state) => {
        state.isLoadingRankingSummary = false;
      });
  }
});

// Selectors
export const selectGlobalRank = state => state.rank.globalRank;
export const selectGlobalRankPages = state => state.rank.globalRankPages;
export const selectMonthlyRank = state => state.rank.monthlyRank;
export const selectMonthlyRankPages = state => state.rank.monthlyRankPages;
export const selectRankSummary = state => state.rank.rankSummary;
export const selectRankUserSummary = state => state.rank.rankUserSummary;
export const selectGlobalRankIsLoading = state => state.rank.isLoadingGlobalRanking;
export const selectMonthlyRankIsLoading = state => state.rank.isLoadingMonthlyRanking;
export const selectRankSummaryIsLoading = state => state.rank.isLoadingRankingSummary;

export default rank.reducer;
