import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Components
import BaseLink from 'components/BaseLink';


const GoBack = ({
  className,
  textColor
}) => {
  const navigate = useNavigate();

  return (
    <div className={`${className ? ` ${className}` : ''}`}>
      <BaseLink
        arrowPosition="left"
        className="mb-5"
        hasArrow
        text="Voltar"
        textColor={textColor}
        type="button"
        onClick={() => navigate(-1)}
      />
    </div>
  );
};

GoBack.propTypes = {
  className: PropTypes.string,
  textColor: PropTypes.oneOf(['dark-blue', 'white'])
};

GoBack.defaultProps = {
  className: null,
  textColor: 'dark-blue'
};


export default GoBack;
