import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, selectAllProducts, selectProductsAreLoading, setTransaction } from 'store/slices/marketplace';

// Components
import BaseButton from 'components/BaseButton';
import Card from 'components/Card';
import FullScreenLoader from 'components/FullScreenLoader';
import Modal from 'components/Modal';
import QuantitySelector from 'components/QuantitySelector';


const Product = ({
  className,
  noShadow
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoadingProducts = useSelector(selectProductsAreLoading);
  const products = useSelector(selectAllProducts);

  // State
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [productQuantity, setProductQuantity] = useState(1);

  const currentProduct = Array.isArray(products) && products.find(item => item.id.toString() === id);

  const {
    imageLink,
    name,
    points,
    stock,
    summary
  } = currentProduct;

  useEffect(() => {
    dispatch(getProducts())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os produtos.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (productQuantity > stock) setErrorMessage(`Ocorreu um erro. Não existe stock suficiente. Neste momento o valor de stock é ${stock}.`);
  }, [productQuantity, stock]);

  const handleGetProductButtonClick = () => {
    dispatch(setTransaction({
      product: parseInt(id, 10),
      quantity: productQuantity === '' ? 1 : productQuantity
    }))
      .then((response) => {
        if (response?.error || typeof response.payload === 'string') {
          setErrorMessage('Ocorreu um erro. Verifique que tem pontos suficientes para trocar pelo produto.');
          throw new Error(response.error?.message || 'Ocorreu um erro ao resgatar o produto.');
        }

        return response;
      })
      .then((response) => {
        setSuccessMessage('Troca efetuada com sucesso! Irá receber um e-mail com a confirmação da transação.');

        return response;
      })
      .catch((error) => {
        setErrorMessage('Ocorreu um erro. Verifique que tem pontos suficientes para trocar pelo produto.');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleProductQuantityBlur = (value) => {
    if (value === '') setProductQuantity(1);
  };

  const handleProductQuantityChange = (quantity) => {
    setProductQuantity(quantity !== '' ? parseInt(quantity, 10) : '');
  };

  const modalTrigger = (
    <BaseButton
      backgroundColor="dark-blue"
      className="mt-5 w-full"
      text="Resgatar"
      type="button"
    />
  );

  return isLoadingProducts ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <Card
          className="h-full"
          noShadow={noShadow}
          smallPadding
        >
          <h2 className="mb-1">
            {name}
          </h2>
          <p className="font-semibold text-orange">
            {points}
            {' '}
            pontos
          </p>
          <img
            alt="Prémio"
            className="w-full rounded-md object-cover my-5 aspect-video"
            src={imageLink}
          />
          <p className="text-text-gray select-none mb-5">
            {summary}
          </p>
          <QuantitySelector
            value={productQuantity}
            onBlur={handleProductQuantityBlur}
            onChange={handleProductQuantityChange}
          />
          {errorMessage && (
            <div className="rounded-md bg-error p-3 mt-3">
              <p className="text-white">
                {errorMessage}
              </p>
            </div>
          )}
          {successMessage && (
            <div className="rounded-[8px] p-3 mt-3 bg-green-600">
              <p className="text-white">
                {successMessage}
              </p>
            </div>
          )}
          <Modal
            description="Pretende resgatar este produto?"
            title="Confirmação"
            trigger={modalTrigger}
            onConfirmation={handleGetProductButtonClick}
          />
        </Card>
      </div>
    );
};

Product.propTypes = {
  className: PropTypes.string,
  noShadow: PropTypes.bool
};

Product.defaultProps = {
  className: null,
  noShadow: false
};


export default Product;
