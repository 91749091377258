/* eslint-disable camelcase */
import { axiosGet, axiosPost } from 'api';
import {
  CHALLENGE_UPDATE,
  CHALLENGES,
  CLASSES,
  COMMENTS,
  LIVE_CLASSES
} from 'api/endpoints';


const fetchChallenges = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${CHALLENGES}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(CHALLENGES);
};

const fetchClassComments = id => axiosGet(`${COMMENTS}${id}`);

const fetchClasses = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${CLASSES}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(CLASSES);
};

const fetchLiveClasses = () => axiosGet(LIVE_CLASSES);

const updateClass = ({
  challenge,
  comment,
  points,
  rating,
  state,
  username,
  video
}) => axiosPost(CHALLENGE_UPDATE, video ?
  video :
  {
    challenge,
    comment,
    points,
    rating,
    state,
    user_record: username
  }, null, video ? { 'Content-Type': 'multipart/form-data' } : null);

export {
  fetchChallenges,
  fetchClassComments,
  fetchClasses,
  fetchLiveClasses,
  updateClass
};
