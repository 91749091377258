import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';

// // Icons
import { ReactComponent as PointsIcon } from 'assets/icons/star-circle.svg';
import { ReactComponent as RankingIcon } from 'assets/icons/medal.svg';


const CardWithNumber = ({
  className,
  color,
  items,
  icon,
  noShadow,
  title
}) => {
  if (!Array.isArray(items) || !items.length) return null;

  return (
    <Card
      className={`${className ? `${className}` : null} p-0`}
      noShadow={noShadow}
    >
      <div className={`flex items-center justify-center py-3 ${
        color === 'blue' ? 'bg-light-blue' : 'bg-orange'}`}
      >
        {!!icon && (
          <div className="text-white mr-2">
            {icon === 'star-circle' && (
              <PointsIcon className="h-5 w-5" />
            )}
            {icon === 'medal' && (
              <RankingIcon className="h-5 w-5" />
            )}
          </div>
        )}
        <div className="text-sm text-white font-semibold uppercase">
          {title}
        </div>
      </div>
      <div className={`${items.length > 1 && !!items.every(item => typeof item.number === 'number') ? 'flex flex-wrap gap-10' : 'flex items-end'} justify-center p-6`}>
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center"
          >
            {(typeof item.number === 'number' || typeof item.number === 'string') && (
              <>
                <p className={`font-semibold text-2xl ${
                  item.color === 'blue' ? 'text-light-blue' : 'text-orange'}`}
                >
                  {item.number}
                </p>
                <p className="text-sm text-text-gray">
                  {item.label}
                </p>
              </>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
};

CardWithNumber.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'orange']),
  icon: PropTypes.oneOf(['star-circle', 'medal']),
  items: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf(['blue', 'orange']),
    label: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })),
  noShadow: PropTypes.bool,
  title: PropTypes.string.isRequired
};

CardWithNumber.defaultProps = {
  className: null,
  color: null,
  icon: null,
  items: null,
  noShadow: false
};


export default CardWithNumber;
