import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import GoBack from './GoBack';


const Layout = ({
  children,
  className,
  hasNavbarAndFooter
}) => {
  const location = useLocation();

  return (
    <main className={`container relative overflow-hidden${
      className ? ` ${className}` : ''}${
      hasNavbarAndFooter ? ' py-16 md:py-24' : ' p-0'}`}
    >
      {location.pathname !== '/' && location.pathname !== '/dashboard' && location.pathname !== '/criar-conta' && location.pathname !== '/recuperar-password' && <GoBack className="absolute top-5 md:top-10" />}
      {children}
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  hasNavbarAndFooter: PropTypes.bool
};

Layout.defaultProps = {
  children: null,
  className: null,
  hasNavbarAndFooter: true
};


export default Layout;
