import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getClasses, getLiveClasses, selectAllClasses, selectAllLiveClasses, selectClassesAreLoading, selectClassesPages } from 'store/slices/classes';
import { getUserDetails, selectUserLevel } from 'store/slices/user';
import { useSearchParams } from 'react-router-dom';

// Components
import ClassesSection from 'components/ClassesSection';
import FullScreenLoader from 'components/FullScreenLoader';
import Pagination from 'components/Pagination';
import UserInfo from 'components/UserInfo';


const Classes = ({
  className,
  link,
  title
}) => {
  const dispatch = useDispatch();
  const classes = useSelector(selectAllClasses);
  const classesAreLoading = useSelector(selectClassesAreLoading);
  const classesPages = useSelector(selectClassesPages);
  const liveClasses = useSelector(selectAllLiveClasses);
  const userLevel = useSelector(selectUserLevel);

  const [allClasses, setAllClasses] = useState([]);
  const [itemsForCurrentUserLevel, setItemsForCurrentUserLevel] = useState([]);
  const [itemsWithoutSpecialEvaluation, setItemsWithoutSpecialEvaluation] = useState([]);
  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [params, setParams] = useSearchParams();

  const pageNumber = params.get('page');

  useEffect(() => {
    if (Array.isArray(classes) && !!classes.length) {
      setAllClasses((prevState) => {
        const items = [...prevState, ...classes];
        const itemsIds = [];

        const uniqueItems = items.filter((element) => {
          const isDuplicate = itemsIds.includes(element.id);

          if (!isDuplicate) {
            itemsIds.push(element.id);

            return true;
          }

          return false;
        });

        return uniqueItems;
      });
    }
  }, [classes]);

  useEffect(() => {
    if (Array.isArray(liveClasses) && !!liveClasses.length) {
      setAllClasses((prevState) => {
        const items = [...prevState, ...liveClasses];
        const itemsIds = [];

        const uniqueItems = items.filter((element) => {
          const isDuplicate = itemsIds.includes(element.id);

          if (!isDuplicate) {
            itemsIds.push(element.id);

            return true;
          }

          return false;
        });

        return uniqueItems;
      });
    }
  }, [liveClasses]);

  const handleNextPageClick = () => {
    dispatch(getClasses(nextPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas.');

        return response;
      })
      .then((response) => {
        setParams({ page: nextPageNumber });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handlePreviousPageClick = () => {
    dispatch(getClasses(previousPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas.');

        return response;
      })
      .then((response) => {
        if (previousPageNumber) setParams({ page: previousPageNumber });
        else params.delete('page');
        setParams(params);

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (classesPages) {
      if (classesPages.next) {
        const nextNumber = classesPages.next.split('page=');

        if (nextNumber[1]) setNextPageNumber(nextNumber[1]);
      }

      if (classesPages.previous) {
        const previousNumber = classesPages.previous.split('page=');

        if (previousNumber[1]) setPreviousPageNumber(previousNumber[1]);
      }
    }
  }, [classesPages]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(getClasses(pageNumber))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      dispatch(getClasses())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getLiveClasses())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter as aulas ao vivo.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserDetails())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os detalhes do utilizador.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(allClasses) && !!allClasses.length) {
      setItemsForCurrentUserLevel(allClasses.filter(item => item.level[0].includes(userLevel)));
    }
  }, [allClasses, userLevel]);

  useEffect(() => {
    if (Array.isArray(itemsForCurrentUserLevel) && itemsForCurrentUserLevel.length) {
      const itemsForCurrentUserLevelArrayCopy = itemsForCurrentUserLevel;

      itemsForCurrentUserLevelArrayCopy.forEach((entry) => {
        if (entry.isSpecialEvaluation) itemsForCurrentUserLevelArrayCopy.splice(itemsForCurrentUserLevelArrayCopy.indexOf(entry), 1);
      });

      setItemsWithoutSpecialEvaluation(itemsForCurrentUserLevelArrayCopy);
    }
  }, [itemsForCurrentUserLevel, userLevel]);


  if (!Array.isArray(allClasses)) return null;

  return classesAreLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <UserInfo />
        <ClassesSection
          className="mt-10"
          items={itemsWithoutSpecialEvaluation}
          link={link}
          showAll
          title={title}
        />
        {!!classesPages && (
          <Pagination
            className="mt-10"
            nextPage={classesPages.next}
            previousPage={classesPages.previous}
            onNextPageClick={handleNextPageClick}
            onPreviousPageClick={handlePreviousPageClick}
          />
        )}
      </div>
    );
};

Classes.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string
};

Classes.defaultProps = {
  className: null,
  title: null
};


export default Classes;
