/* eslint-disable sort-keys */
import axios from 'axios';
import LocalStorageManager from 'utils/LocalStorageManager';
import store from 'store';
import { logout } from 'store/slices/user';

import Cookies from 'js-cookie';


const createHeaders = (customHeaders = {}) => ({
  // 'Content-type': 'application/json',
  Authorization: `Bearer ${LocalStorageManager.getAuthenticationToken()}`,
  ...customHeaders
});

const createUrl = path => `${LocalStorageManager.getApiBaseUrl()}${path}`;

const isErrorResponse = (response) => {
  const { status } = response;

  return status >= 400 && status < 600 && status !== 401;
};

const axiosDelete = (path, params) => {
  const url = createUrl(path);
  const config = { params, headers: createHeaders() };

  return axios.delete(url, config)
    .then(response => response.data);
};

const axiosPost = (path, data = null, params, headers) => {
  const url = createUrl(path);
  const config = { params, headers: createHeaders(headers), withCredentials: true };

  return axios.post(url, data, config)
    .then(async (response) => {
      const hasError = isErrorResponse(response);

      if (hasError) {
        const { data: errorMessages } = response;

        return Promise.reject(errorMessages);
      }

      if (response && response.status === 401) {
        if (response.config.url.includes('/token')) {
          store.dispatch(logout());
        } else {
          await axiosPost('token/refresh/', {
            refresh: LocalStorageManager.getAuthenticationTokenRefresh()
          })
            .then((responseData) => {
              if (responseData.access) {
                LocalStorageManager.setAuthenticationToken(responseData.access);
                Cookies.set('csrftoken', responseData.access);
              }
            });

          return axiosPost(path, data, params);
        }
      }

      if (response && response.data) {
        if (response.data.detail) throw response.data;

        return response.data;
      }

      return response;
    });
};

const axiosPostNoHeaders = (path, data = null, params) => {
  const url = createUrl(path);
  const config = { params, withCredentials: true };

  return axios.post(url, data, config)
    .then(async (response) => {
      const hasError = isErrorResponse(response);

      if (hasError) {
        const { data: errorMessages } = response;

        return Promise.reject(errorMessages);
      }

      if (response && response.status === 401) {
        if (response.config.url.includes('/token')) {
          store.dispatch(logout());
        } else {
          await axiosPost('token/refresh/', {
            refresh: LocalStorageManager.getAuthenticationTokenRefresh()
          })
            .then((responseData) => {
              if (responseData.access) {
                LocalStorageManager.setAuthenticationToken(responseData.access);
                Cookies.set('csrftoken', responseData.access);
              }
            });

          return axiosPost(path, data, params);
        }
      }

      if (response && response.data) {
        if (response.data.detail) throw response.data;

        return response.data;
      }

      return response;
    });
};

const axiosGet = (path, params, headers) => {
  const url = createUrl(path);
  const config = { params, headers: createHeaders(headers), data: null };

  return axios.get(url, config)
    .then(async (response) => {
      const hasError = isErrorResponse(response);

      if (hasError) {
        const { data: errorMessages } = response;

        return Promise.reject(errorMessages);
      }

      if (response) {
        if (response.status === 401 && !response.config.url.includes('/token')) {
          await axiosPost('token/refresh/', {
            refresh: LocalStorageManager.getAuthenticationTokenRefresh()
          })
            .then((responseData) => {
              if (responseData.access) {
                LocalStorageManager.setAuthenticationToken(responseData.access);
                Cookies.set('csrftoken', responseData.access);
              }
            });

          return axiosGet(path, params, headers);
        }

        if (response.data) {
          return response.data;
        }
      }

      return response;
    });
};

const axiosGetNoHeaders = (path, params) => {
  const url = createUrl(path);
  const config = { params, data: null };

  return axios.get(url, config)
    .then(async (response) => {
      const hasError = isErrorResponse(response);

      if (hasError) {
        const { data: errorMessages } = response;

        return Promise.reject(errorMessages);
      }

      if (response) {
        if (response.status === 401 && !response.config.url.includes('/token')) {
          await axiosPost('token/refresh/', {
            refresh: LocalStorageManager.getAuthenticationTokenRefresh()
          })
            .then((responseData) => {
              if (responseData.access) {
                LocalStorageManager.setAuthenticationToken(responseData.access);
                Cookies.set('csrftoken', responseData.access);
              }
            });

          return axiosGet(path, params);
        }

        if (response.data) {
          return response.data;
        }
      }

      return response;
    });
};

const axiosPut = (path, data = null, params) => {
  const url = createUrl(path);
  const config = { params, headers: createHeaders() };

  return axios.put(url, data, config)
    .then(response => response.data);
};

axios.interceptors.response.use(response => response, error => error.response);

export {
  axiosDelete,
  axiosGet,
  axiosGetNoHeaders,
  axiosPost,
  axiosPostNoHeaders,
  axiosPut
};
