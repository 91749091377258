const formatDuration = (string) => {
  const splitTimeString = string.split(':');

  let finalTime = `${splitTimeString[1]}min`;

  if (splitTimeString[0] !== '00') finalTime = `${splitTimeString[0]}h${splitTimeString[1]}min`;

  return finalTime;
};

export default formatDuration;
