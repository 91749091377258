import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

// Store
import { getLoginBanner, getUserDetails, login, selectLoginImage, selectUserIsLoggingIn } from 'store/slices/user';

// Components
import BaseButton from 'components/BaseButton';
import BaseLink from 'components/BaseLink';
import BaseInput from 'components/BaseInput';
import Card from 'components/Card';

// Icons
import eye from 'assets/icons/eye.svg';
import eyeSlash from 'assets/icons/eye-slash.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';


const Login = ({
  className
}) => {
  const dispatch = useDispatch();
  const isLoggingIn = useSelector(selectUserIsLoggingIn);
  const image = useSelector(selectLoginImage);

  const [formError, setFormError] = useState(null);
  const [inputType, setInputType] = useState('password');

  const { handleSubmit, formState: { errors }, control, register, reset, watch } = useForm({
    defaultValues: {
      password: '',
      username: ''
    },
    reValidateMode: 'onChange'
  });

  const handleInputChange = (value, field) => {
    field.onChange(value);
  };

  const handleInputIconClick = () => {
    if (inputType === 'password') setInputType('text');
    else setInputType('password');
  };

  const isFormValid = () => {
    const inputs = ['username', 'password'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const handleFormSubmit = (data) => {
    if (isFormValid) {
      const { username, password } = data;
      const userData = {
        email: username,
        password
      };

      dispatch(login(userData))
        .then((response) => {
          if (response?.error || !response.payload) throw new Error(response.error.message || 'Ocorreu um erro. Certifique-se que introduziu um e-mail e password corretos.');

          return response;
        })
        .then((response) => {
          dispatch(getUserDetails());

          return response;
        })
        .catch((error) => {
          reset();
          setFormError('Ocorreu um erro. Certifique-se que introduziu um e-mail e password corretos.');
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  useEffect(() => {
    dispatch(getLoginBanner())
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`min-h-screen min-w-full flex justify-center${className ? ` ${className}` : ''}`}>
      <div className="absolute top-[-50vh] md:top-[-55vh] left-1/2 -translate-x-1/2 h-[100vh] w-[110vw] md:w-[140vw] rounded-[100vh/50vh] bg-gradient-to-r from-blue to-light-blue z-10" />
      <div className="container mt-6 z-10">
        <div className="max-w-sm mx-auto mb-6">
          <div className="w-24 md:w-32 mx-auto">
            <Logo className="h-auto w-full" />
            <hr className="h-1 border-top border-white mt-5 mb-1" />
          </div>
          <h1 className="font-extrabold text-white text-center uppercase text-2xl md:text-4xl">
            Training
          </h1>
        </div>
        <Card className="max-w-sm mx-auto p-5 md:p-8">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <BaseInput
                  error={errors.username?.type === 'required' ? 'Insira o seu username.' : ''}
                  inputClassName="leading-normal py-2 min-h-min h-auto md:h-[3rem]"
                  label="Username ou e-mail"
                  placeholder="O seu username ou e-mail"
                  type="text"
                  {...register('username')}
                  onChange={event => handleInputChange(event?.target?.value, field)}
                />
              )}
              rules={{ required: true }}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <BaseInput
                  className="mt-2"
                  error={errors.password?.type === 'required' ? 'Insira a sua password.' : ''}
                  icon={inputType === 'password' ? eyeSlash : eye}
                  inputClassName="leading-normal py-2 min-h-min h-auto md:h-[3rem]"
                  label="Password"
                  link={{
                    path: '/recuperar-password',
                    text: 'Recuperar Password'
                  }}
                  placeholder="Password"
                  type={inputType}
                  {...register('password')}
                  onChange={event => handleInputChange(event?.target?.value, field)}
                  onIconClick={() => handleInputIconClick(field)}
                />
              )}
              rules={{ required: true }}
            />
            {!!formError && (
              <div className="text-xs text-error mt-5">
                {formError}
              </div>
            )}
            <BaseButton
              className="mt-4 w-full text-sm py-2 min-h-min h-auto md:min-h-[3rem]"
              loading={isLoggingIn}
              text={!isLoggingIn ? 'Login' : ''}
              type="submit"
            />
          </form>
          <div className="mt-5 mx-auto">
            <p className="text-text-gray text-xs">
              Ainda não está registado?
              <BaseLink
                className="font-normal text-xs ml-1"
                external
                path="https://registo-training.grupoalvesbandeira.com/"
                text="Registar agora"
              />
            </p>
          </div>
        </Card>
      </div>
      <img
        alt="GAB Training"
        className="absolute bottom-0 left-0 h-[70vh] w-full object-cover object-center overflow-hidden"
        src={image}
      />
      <div className="absolute top-0 left-0 h-full w-full bg-[#003C78] opacity-50" />
    </div>
  );
};

Login.propTypes = {
  className: PropTypes.string
};

Login.defaultProps = {
  className: null
};


export default Login;
