import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNews, selectAllNews, selectIndivualNewsIsLoading } from 'store/slices/news';

// Components
import Card from 'components/Card';
import FullScreenLoader from 'components/FullScreenLoader';


const News = ({
  className,
  noShadow
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const news = useSelector(selectAllNews);
  const newsIsLoading = useSelector(selectIndivualNewsIsLoading);
  const currentNews = Array.isArray(news) && news.find(item => item.id.toString() === id);

  useEffect(() => {
    dispatch(getNews())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter o conteúdo do artigo.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  const {
    date,
    image,
    text,
    title
  } = currentNews;

  return newsIsLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <Card
          className="h-full"
          noShadow={noShadow}
          smallPadding
        >
          <h2 className="mb-1">
            {title}
          </h2>
          <p className="font-semibold text-orange">
            {date}
          </p>
          <img
            alt="Novidade"
            className="w-full rounded-md object-cover aspect-video my-5"
            src={image}
          />
          <p
            className="text-text-gray"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Card>
      </div>
    );
};

News.propTypes = {
  className: PropTypes.string,
  noShadow: PropTypes.bool
};

News.defaultProps = {
  className: null,
  noShadow: false
};


export default News;
