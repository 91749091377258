import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsLoading, selectUserProfile, setUserDetails } from 'store/slices/user';

// Reakit
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import { Input } from 'reakit/Input';

// Components
import BaseLink from 'components/BaseLink';

// Icons
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import userIcon from 'assets/icons/user.svg';


const UserInfo = ({
  className
}) => {
  const popover = usePopoverState({ gutter: 4, placement: 'bottom-start' });
  const dispatch = useDispatch();
  const user = useSelector(selectUserProfile);
  const userIsLoading = useSelector(selectUserIsLoading);
  const avatar = user?.photo;
  const [errorMessage, setErrorMessage] = useState(false);

  const formData = new FormData();

  const handleAddPhotoButtonClick = (files) => {
    popover.hide();
    formData.append('photo', files[0]);

    dispatch(setUserDetails({
      photo: formData
    }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao alterar o seu avatar. Por favor tente novamente.');

        return response;
      })
      .catch((error) => {
        setErrorMessage(error || 'Ocorreu um erro ao alterar o seu avatar. Por favor tente novamente.');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleRemovePhotoButtonClick = () => {
    popover.hide();
    formData.append('photo', '');

    dispatch(setUserDetails({
      photo: formData
    }))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao alterar o seu avatar. Por favor tente novamente.');

        return response;
      })
      .catch((error) => {
        setErrorMessage(error || 'Ocorreu um erro ao alterar o seu avatar. Por favor tente novamente.');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  if (!user?.firstName || !user?.lastName || !user?.username) return null;

  return (
    <>
      <div className={`flex items-center select-none${className ? ` ${className}` : ''}`}>
        <div className={`relative flex items-center justify-center h-20 w-20 rounded-full${userIsLoading ? ' bg-dark-blue/[0.5]' : ''}`}>
          {userIsLoading ?
            (
              <Loading className="animate-spin h-5 w-5" />
            ) :
            (
              <img
                alt="User avatar"
                className="h-20 w-20 rounded-full object-cover object-center overflow-hidden"
                src={avatar || userIcon}
              />
            )}
          <div className="absolute right-0 bottom-0 hover:cursor-pointer z-10">
            <PopoverDisclosure {...popover}>
              <div className="relative flex items-center justify-center h-6 w-6 bg-white rounded-full drop-shadow-sm">
                <PlusIcon className="text-dark-blue h-3 w-3" />
              </div>
            </PopoverDisclosure>
            <Popover
              className="shadow-lg p-1 bg-white rounded-2xl min-w-[150px]"
              {...popover}
            >
              <div className="relative text-sm font-medium text-light-blue block rounded-xl p-3 mb-1 outline-none last-of-type:mb-0 hover:text-blue hover:bg-light-blue/20 focus:bg-light-blue/20">
                <label>
                  {`${avatar ? 'Alterar' : 'Adicionar'} foto`}
                </label>
                <Input
                  className="absolute top-0 left-0 h-full w-full indent-96 hover:cursor-pointer"
                  type="file"
                  onChange={event => handleAddPhotoButtonClick(event?.target?.files)}
                />
              </div>
              {avatar && (
                <BaseLink
                  className="text-left rounded-xl p-3 mb-1 w-full outline-none last-of-type:mb-0 hover:bg-light-blue/20 focus:bg-light-blue/20"
                  text="Remover foto"
                  textColor="light-blue"
                  type="button"
                  onClick={handleRemovePhotoButtonClick}
                />
              )}
            </Popover>
          </div>
        </div>
        <div className="ml-3">
          <h4>
            {user.firstName}
          </h4>
          <div className="text-sm text-light-blue">
            <span className="text-dark-blue mr-2">
              Nível:
            </span>
            {user.level?.title || 'Iniciante'}
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className="rounded-md bg-error p-3 mb-5">
          <p className="text-white">
            {errorMessage}
          </p>
        </div>
      )}
    </>
  );
};

UserInfo.propTypes = {
  className: PropTypes.string
};

UserInfo.defaultProps = {
  className: null
};


export default UserInfo;
