import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

// Store
import { getUserDetails, setUserPassword } from 'store/slices/user';

// Components
import BaseButton from 'components/BaseButton';
import BaseInput from 'components/BaseInput';

// Icons
import eye from 'assets/icons/eye.svg';
import eyeSlash from 'assets/icons/eye-slash.svg';


const RegisterForm = ({
  className
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formError, setFormError] = useState(null);
  const [inputType, setInputType] = useState('password');

  const { handleSubmit, formState: { errors }, control, register, watch } = useForm({
    defaultValues: {
      password: ''
    },
    reValidateMode: 'onChange'
  });

  const handleInputChange = (value, field) => {
    field.onChange(value);
  };

  const handleInputIconClick = () => {
    if (inputType === 'password') setInputType('text');
    else setInputType('password');
  };

  const isFormValid = () => {
    const inputs = ['password'];

    return inputs.every(entry => !!watch(entry) && !errors[entry]);
  };

  const handleFormSubmit = (password) => {
    if (isFormValid) {
      dispatch(setUserPassword(password))
        .then((response) => {
          if (response?.error || response.payload?.error) throw new Error(response.error?.message || response.payload.error || 'Ocorreu um erro ao alterar a sua password. Por favor tente novamente.');

          return response;
        })
        .then((response) => {
          dispatch(getUserDetails());
          navigate('/perfil');

          return response;
        })
        .catch((error) => {
          setFormError('Ocorreu um erro ao alterar a sua password. Por favor tente novamente.');
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  return (
    <form
      className={`${className ? ` ${className}` : ''}`}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {!!formError && (
        <div className="text-xs text-error mb-5">
          {formError}
        </div>
      )}
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <BaseInput
            error={errors.password?.type === 'required' ? 'Certifique-se que inseriu uma password.' : ''}
            icon={inputType === 'password' ? eyeSlash : eye}
            label="Nova Password"
            placeholder="A sua nova password"
            type={inputType}
            {...register('password')}
            onChange={event => handleInputChange(event?.target?.value, field)}
            onIconClick={() => handleInputIconClick(field)}
          />
        )}
        rules={{ required: true }}
      />
      <BaseButton
        className="mt-5 w-full"
        text="Continuar"
        type="submit"
      />
    </form>
  );
};

RegisterForm.propTypes = {
  className: PropTypes.string
};

RegisterForm.defaultProps = {
  className: null
};


export default RegisterForm;
