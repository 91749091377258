import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthlyRank, selectMonthlyRank, selectMonthlyRankIsLoading, selectMonthlyRankPages } from 'store/slices/rank';
import { selectUserProfile } from 'store/slices/user';
import { useSearchParams } from 'react-router-dom';

// Components
import Card from 'components/Card';
import FullScreenLoader from 'components/FullScreenLoader';
import Pagination from 'components/Pagination';

// Icons
import userIcon from 'assets/icons/user.svg';


const API_IMAGES_URL = 'https://d1nbb270y0xfhp.cloudfront.net/';

const MonthlyRanking = ({
  className
}) => {
  const dispatch = useDispatch();
  const isLoadingMonthlyRank = useSelector(selectMonthlyRankIsLoading);
  const monthlyRank = useSelector(selectMonthlyRank);
  const monthlyRankPages = useSelector(selectMonthlyRankPages);
  const user = useSelector(selectUserProfile);

  const [nextPageNumber, setNextPageNumber] = useState(null);
  const [previousPageNumber, setPreviousPageNumber] = useState(null);
  const [params, setParams] = useSearchParams();

  const pageNumber = params.get('page');

  const handleNextPageClick = () => {
    dispatch(getMonthlyRank(nextPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

        return response;
      })
      .then((response) => {
        setParams({ page: nextPageNumber });

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handlePreviousPageClick = () => {
    dispatch(getMonthlyRank(previousPageNumber))
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

        return response;
      })
      .then((response) => {
        if (previousPageNumber) setParams({ page: previousPageNumber });
        else params.delete('page');
        setParams(params);

        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    if (monthlyRankPages) {
      if (monthlyRankPages.next) {
        const nextNumber = monthlyRankPages.next.split('page=');

        if (nextNumber[1]) setNextPageNumber(nextNumber[1]);
      }

      if (monthlyRankPages.previous) {
        const previousNumber = monthlyRankPages.previous.split('page=');

        if (previousNumber[1]) setPreviousPageNumber(previousNumber[1]);
      }
    }
  }, [monthlyRankPages]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(getMonthlyRank(pageNumber))
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      dispatch(getMonthlyRank())
        .then((response) => {
          if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

          return response;
        })
        .then(response => response)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoadingMonthlyRank ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        {Array.isArray(monthlyRank) && monthlyRank.length > 0 && (
          <>
            <h2 className="mb-10">
              Ranking Mensal
            </h2>
            <Card smallPadding>
              {monthlyRank.map((entry, index) => (
                <div
                  key={index}
                  className={`flex px-3 py-2 items-center justify-between mb-4 last-of-type:mb-0${entry.username === user.username ? ' rounded-lg bg-light-blue bg-opacity-10' : ''}`}
                >
                  <div className="inline-flex items-center">
                    <div className="font-semibold text-light-blue min-w-[30px]">
                      {index + 1}
                      .
                    </div>
                    <img
                      alt="User avatar"
                      className="h-10 w-10 mx-5 rounded-full object-cover object-center overflow-hidden"
                      src={entry.photo ? `${API_IMAGES_URL}${entry.photo}` : userIcon}
                    />
                    <div className="text-sm font-semibold text-light-blue">
                      {entry.username}
                    </div>
                  </div>
                  <div className="text-sm font-semibold text-orange">
                    {entry.totalPoints}
                    {' '}
                    pontos
                  </div>
                </div>
              ))}
            </Card>
          </>
        )}
        {!!monthlyRankPages && (
          <Pagination
            className="mt-10"
            nextPage={monthlyRankPages.next}
            previousPage={monthlyRankPages.previous}
            onNextPageClick={handleNextPageClick}
            onPreviousPageClick={handlePreviousPageClick}
          />
        )}
      </div>
    );
};

MonthlyRanking.propTypes = {
  className: PropTypes.string
};

MonthlyRanking.defaultProps = {
  className: null
};


export default MonthlyRanking;
