/* eslint-disable camelcase */
import { axiosGet, axiosPost } from 'api';
import {
  POINTS_HISTORY,
  PRODUCTS,
  TRANSACTIONS
} from 'api/endpoints';


const fetchPointsTransactions = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${POINTS_HISTORY}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(POINTS_HISTORY);
};

const fetchProducts = (pageNumber) => {
  if (pageNumber) {
    const endpoint = `${PRODUCTS}?page=${pageNumber}`;

    return axiosGet(endpoint);
  }

  return axiosGet(PRODUCTS);
};

const makeTransaction = ({
  product,
  quantity
}) => axiosPost(TRANSACTIONS, {
  product,
  quantity
});

export {
  fetchPointsTransactions,
  fetchProducts,
  makeTransaction
};
