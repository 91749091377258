import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseLink from 'components/BaseLink';
import NewsCard from 'components/NewsCard';


const NewsSection = ({
  className,
  items,
  link,
  showAll,
  title,
  visibleItemsNumber
}) => {
  if (!Array.isArray(items) || !items.length) return null;

  const slicedNews = items.slice(0, visibleItemsNumber);
  const displayedNews = showAll ? items : slicedNews;

  return (
    <div className={className ? className : ''}>
      {!!title && (
        <h2 className="mb-10">
          {title}
        </h2>
      )}
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {displayedNews.map((item, index) => (
          <NewsCard
            key={index}
            date={item.date}
            id={item.id}
            image={item.image}
            summary={item.text}
            title={item.title}
          />
        ))}
      </div>
      {!showAll && (
        <div className="flex justify-end mt-8">
          <BaseLink
            arrowPosition="right"
            hasArrow
            path={link}
            text="Ver todas"
          />
        </div>
      )}
    </div>
  );
};

NewsSection.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    summary: PropTypes.string,
    title: PropTypes.string.isRequired
  })).isRequired,
  link: PropTypes.string.isRequired,
  showAll: PropTypes.bool,
  title: PropTypes.string,
  visibleItemsNumber: PropTypes.number
};

NewsSection.defaultProps = {
  className: null,
  showAll: false,
  title: null,
  visibleItemsNumber: null
};


export default NewsSection;
