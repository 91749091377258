import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

// Components
import Menu from 'components/Menu';
import MobileMenu from 'components/MobileMenu';

// Icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';


const MainHeader = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMenuIconClick = (event) => {
    if ((event.type === 'click' || event.key === 'Enter')) {
      setShowMobileMenu(prevState => !prevState);
    }
  };

  const handleMobileMenuLinkClick = () => {
    setShowMobileMenu(prevState => !prevState);
  };

  const handleResize = useDebouncedCallback(() => {
    if (window.innerWidth > 1280 && showMobileMenu) {
      setShowMobileMenu(false);
    }
  }, 300);

  useEffect(() => {
    if (showMobileMenu) document.body.classList.add('no-scroll');
    else document.body.classList.remove('no-scroll');
  }, [showMobileMenu]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.classList.remove('no-scroll');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="bg-gradient-to-r from-blue to-light-blue py-5 xl:py-7">
      <div className="container">
        <div className="navbar flex place-content-between p-0">
          <Link to="/dashboard">
            <Logo className="h-auto w-24 xl:w-28" />
          </Link>
          <Menu />
          <div
            className="xl:hidden"
            role="button"
            tabIndex={0}
            onClick={handleMenuIconClick}
            onKeyDown={handleMenuIconClick}
          >
            {showMobileMenu ? <CloseIcon className="text-white" /> : <MenuIcon />}
          </div>
        </div>
        {showMobileMenu && (
          <MobileMenu
            onLinkClick={handleMobileMenuLinkClick}
          />
        )}
      </div>
    </header>
  );
};

export default MainHeader;
