import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';


const MultiRangeSlider = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    value => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({
      max: maxVal,
      min: minVal
    });
  }, [minVal, maxVal, onChange]);

  const inputClasses = 'absolute h-0 w-[200px] outline-none pointer-events-none thumb';

  return (
    <>
      <input
        ref={minValRef}
        className={`${inputClasses}${minVal > max ? ' z-50' : ' z-30'}`}
        max={max}
        min={min}
        type="range"
        value={minVal}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);

          setMinVal(value);
          event.target.value = value.toString();
        }}
      />
      <input
        ref={maxValRef}
        className={`${inputClasses} z-40`}
        max={max}
        min={min}
        type="range"
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);

          setMaxVal(value);
          event.target.value = value.toString();
        }}
      />
      <div className="relative w-[200px]">
        <div className="absolute rounded-sm h-1 bg-border-gray w-full z-10" />
        <div
          ref={range}
          className="absolute rounded-sm h-1 bg-light-blue z-20"
        />
        <div className="absolute left-[2px] text-xs text-dark-blue mt-5">
          {minVal}
          {' '}
          pontos
        </div>
        <div className="absolute right-[-1px] text-xs text-dark-blue mt-5">
          {maxVal}
          {' '}
          pontos
        </div>
      </div>
    </>
  );
};

MultiRangeSlider.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
