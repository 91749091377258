import React from 'react';
import PropTypes from 'prop-types';

// Reakit
import {
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
  useDialogState
} from 'reakit/Dialog';

// Components
import BaseButton from 'components/BaseButton';

// Images
import { ReactComponent as Close } from 'assets/icons/close.svg';


const Modal = ({
  description,
  onConfirmation,
  title,
  trigger
}) => {
  const dialog = useDialogState();

  const handleConfirmationClick = () => {
    onConfirmation();
    dialog.hide();
  };

  const handleModalClose = () => {
    dialog.hide();
  };

  return (
    <>
      <DialogDisclosure
        ref={trigger.ref}
        {...dialog}
        {...trigger.props}
      >
        {triggerProps => React.cloneElement(trigger, triggerProps)}
      </DialogDisclosure>
      <DialogBackdrop
        className="fixed h-full w-full top-0 bg-black bg-opacity-50 z-40"
        {...dialog}
      >
        <Dialog
          className="fixed bg-white rounded-md p-5 min-w-[300px] left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 z-50"
          {...dialog}
        >
          <Close
            className="absolute top-2 right-2 h-5 w-5 text-dark-blue hover:cursor-pointer"
            onClick={handleModalClose}
          />
          <h5 className="text-center mb-2">
            {title}
          </h5>
          <p className="text-center text-light-blue mb-2">
            {!!description && (
              description
            )}
          </p>
          <div className="flex justify-center mt-5">
            <BaseButton
              className="mr-2"
              text="Sim"
              onClick={handleConfirmationClick}
            />
            <BaseButton
              text="Não"
              onClick={handleModalClose}
            />
          </div>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

Modal.propTypes = {
  description: PropTypes.string,
  onConfirmation: PropTypes.func,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired
};

Modal.defaultProps = {
  description: null,
  onConfirmation: () => {}
};

Modal.displayName = 'Modal';

export default Modal;
