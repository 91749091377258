import React from 'react';
import PropTypes from 'prop-types';


const Card = ({
  children,
  className,
  smallPadding,
  noShadow
}) => (
  <div className={`card${
    !noShadow ? ' shadow-xl' : ''}${
    smallPadding ? ' p-5' : ''}${
    className ? ` ${className}` : ''}`}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  noShadow: PropTypes.bool,
  smallPadding: PropTypes.bool
};

Card.defaultProps = {
  children: null,
  className: null,
  noShadow: false,
  smallPadding: false
};

export default Card;
