import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Reakit
import { Input } from 'reakit/Input';

// Icons
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';


const QuantitySelector = ({
  className,
  disabled,
  error,
  onBlur,
  onChange,
  value,
  ...props
}) => {
  const input = useRef(null);
  const inputClasses = `input bg-background-gray my-2 w-full select-none mr-2 focus:shadow-input${disabled ? ' text-text-gray' : ''}${error ? ' border-solid border-2 border-error' : ''}`;
  const labelClasses = 'label p-0';
  const labelTextClasses = `label-text font-semibold text-dark-blue${disabled ? ' opacity-50' : ''}`;

  const handleAddQuantityButtonClick = () => {
    const newValue = value + 1;

    onChange(newValue);
  };

  const handleRemoveQuantityButtonClick = () => {
    const newValue = value === 1 ? 1 : value - 1;

    onChange(newValue);
  };

  const handleQuantityValueChange = (event) => {
    onChange(event?.target?.value);
  };

  return (
    <div className={`form-control ${className ? ` ${className}` : ''}`}>
      <label
        className={labelClasses}
        htmlFor={input}
      >
        <span className={labelTextClasses}>
          Quantidade
        </span>
      </label>
      <div className="flex items-center">
        <Input
          ref={input}
          className={inputClasses}
          disabled={disabled}
          placeholder="Quantidade"
          type="number"
          value={value}
          onBlur={event => onBlur(event?.target?.value)}
          onChange={handleQuantityValueChange}
          {...props}
        />
        <button
          className="flex items-center justify-center bg-background-gray rounded-md h-12 min-w-[48px] mr-2 hover:cursor-pointer"
          type="button"
          onClick={handleRemoveQuantityButtonClick}
        >
          <Minus className="h-3 w-3" />
        </button>
        <button
          className="flex items-center justify-center bg-background-gray rounded-md h-12 min-w-[48px] hover:cursor-pointer"
          type="button"
          onClick={handleAddQuantityButtonClick}
        >
          <Plus className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};

QuantitySelector.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

QuantitySelector.defaultProps = {
  className: null,
  disabled: false,
  error: null,
  onBlur: () => {},
  onChange: () => {},
  value: 1
};

export default QuantitySelector;
