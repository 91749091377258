import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initialize as initializeLogin, logout, selectAuthenticationToken, selectInitialized, selectUserProfile } from 'store/slices/user';

// Utils
import LocalStorageManager from 'utils/LocalStorageManager';

// Routes
import AllNews from 'routes/AllNews';
import Challenges from 'routes/Challenges';
import Classes from 'routes/Classes';
import Class from 'routes/Class';
import Dashboard from 'routes/Dashboard';
import GlobalRanking from 'routes/GlobalRanking';
import HowItWorks from 'routes/HowItWorks';
import Login from 'routes/Login';
import Marketplace from 'routes/Marketplace';
import MonthlyRanking from 'routes/MonthlyRanking';
import News from 'routes/News';
import NotFoundPage from 'routes/404';
import PasswordRecover from 'routes/PasswordRecover';
import Product from 'routes/Product';
import Ranking from 'routes/Ranking';
import Register from 'routes/Register';
import Transactions from 'routes/Transactions';
import UserProfile from 'routes/UserProfile';

// Components
import Layout from 'components/Layout';
import MainHeader from 'components/MainHeader';

import './App.css';


const API_BASE_URL = 'https://training.grupoalvesbandeira.com/api/';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [initialPage, setInitialPage] = useState(null);

  // Selectors
  const authenticationInitialized = useSelector(selectInitialized);
  const authToken = useSelector(selectAuthenticationToken);
  const userProfile = useSelector(selectUserProfile);

  const userProfileIsValid = !!userProfile && !!userProfile.username && !!userProfile.firstName;
  const showDashboard = !!authToken && userProfileIsValid;
  const showHeaderAndFooter = !!authToken && !!userProfile;

  useEffect(() => {
    LocalStorageManager.setApiBaseUrl(API_BASE_URL);
  }, []);

  useEffect(() => {
    dispatch(initializeLogin());
  }, [dispatch]);

  useEffect(() => {
    if (authenticationInitialized && location.pathname === '/') {
      if (authToken && userProfile) {
        if (!userProfileIsValid) setInitialPage(<Register />);
        else navigate('/dashboard');
      } else {
        setInitialPage(<Login />);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, authenticationInitialized, userProfile, userProfileIsValid]);

  useEffect(() => {
    if (!LocalStorageManager.getAuthenticationToken() || !LocalStorageManager.getUserProfile()) {
      dispatch(logout());
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showHeaderAndFooter && <MainHeader />}
      <Layout
        className={`${!showDashboard ? 'max-w-full' : ''}`}
        hasNavbarAndFooter={showHeaderAndFooter}
      >
        <Routes>
          <Route
            element={initialPage}
            path="/"
          />
          <Route
            element={<Register />}
            path="/criar-conta"
          />
          <Route
            element={<PasswordRecover />}
            path="/recuperar-password"
          />
          <Route
            element={
              <UserProfile />
            }
            path="/perfil"
          />
          <Route
            element={<Dashboard />}
            path="/dashboard"
          />
          <Route
            element={<HowItWorks />}
            path="/como-funciona"
          />
          <Route
            element={
              <Classes
                link="/aulas"
                title="Aulas"
              />
            }
            path="/aulas"
          />
          <Route
            path="/aula"
          >
            <Route
              element={<Class />}
              path=":id"
            />
          </Route>
          <Route
            element={
              <Challenges
                link="/desafios"
                title="Desafios"
              />
            }
            path="/desafios"
          />
          <Route
            path="/desafio"
          >
            <Route
              element={<Class />}
              path=":id"
            />
          </Route>
          <Route
            element={
              <GlobalRanking
                link="/ranking-global"
                title="Ranking Global"
              />
            }
            path="/ranking-global"
          />
          <Route
            element={
              <MonthlyRanking
                link="/ranking-mensal"
                title="Ranking Mensal"
              />
            }
            path="/ranking-mensal"
          />
          <Route
            element={
              <Ranking
                link="/ranking"
                title="Ranking"
              />
            }
            path="/ranking"
          />
          <Route
            element={
              <Marketplace
                link="/loja"
                title="Loja"
              />
            }
            path="/loja"
          />
          <Route
            path="/produto"
          >
            <Route
              element={<Product />}
              path=":id"
            />
          </Route>
          <Route
            element={
              <AllNews
                link="/novidades"
                title="Dicas e Novidades"
              />
            }
            path="/novidades"
          />
          <Route
            path="/novidade"
          >
            <Route
              element={<News />}
              path=":id"
            />
          </Route>
          <Route
            element={
              <Transactions
                link="/movimentos"
                title="Movimentos"
              />
            }
            path="/movimentos"
          />
          <Route
            element={<NotFoundPage />}
            path="*"
          />
        </Routes>
      </Layout>
    </>
  );
};

export default App;
