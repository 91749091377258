import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDashboard, selectAllChallenges, selectAllClasses, selectAllLiveClasses, selectDashboardIsLoading, selectDashboardUserData } from 'store/slices/dashboard';
import { getUserRankSummary, selectRankUserSummary } from 'store/slices/rank';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, selectUserLevel } from 'store/slices/user';

// Components
import ClassesSection from 'components/ClassesSection';
import FullScreenLoader from 'components/FullScreenLoader';
import UserStatsCards from 'components/UserStatsCards';
import UserInfo from 'components/UserInfo';


const Dashboard = ({
  className
}) => {
  const dispatch = useDispatch();
  const dashboardIsLoading = useSelector(selectDashboardIsLoading);
  const challenges = useSelector(selectAllChallenges);
  const classes = useSelector(selectAllClasses);
  const liveClasses = useSelector(selectAllLiveClasses);
  const rankUserSummary = useSelector(selectRankUserSummary);
  const userData = useSelector(selectDashboardUserData);
  const userLevel = useSelector(selectUserLevel);

  const [challengesToShowForCurrentUserLevel, setChallengesToShowForCurrentUserLevel] = useState([]);
  const [challengesWithoutEvalutaion, setChallengesWithoutEvalutaion] = useState([]);
  const [classesToShowForCurrentUserLevel, setClassesToShowForCurrentUserLevel] = useState([]);
  const [evaluationChallenge, setEvaluationChallenge] = useState(null);
  const [liveClassesToShowForCurrentUserLevel, setLiveClassesToShowForCurrentUserLevel] = useState([]);

  const userGlobalRank = rankUserSummary?.userGlobalRank;
  const userMonthlyRank = rankUserSummary?.userMonthlyRank;

  useEffect(() => {
    if (Array.isArray(challenges) && !!challenges.length) {
      const evaluation = challenges.find(entry => entry.isSpecialEvaluation);

      if (evaluation) setEvaluationChallenge(evaluation);

      setChallengesToShowForCurrentUserLevel(challenges.filter(item => item.level[0].includes(userLevel)));
    }
  }, [challenges, userLevel]);

  useEffect(() => {
    if (Array.isArray(classes) && !!classes.length) {
      setClassesToShowForCurrentUserLevel(classes.filter(item => item.level[0].includes(userLevel)));
    }
  }, [classes, userLevel]);

  useEffect(() => {
    if (Array.isArray(liveClasses) && !!liveClasses.length) {
      setLiveClassesToShowForCurrentUserLevel(liveClasses.filter(item => item.level[0].includes(userLevel)));
    }
  }, [liveClasses, userLevel]);

  useEffect(() => {
    if (Array.isArray(challengesToShowForCurrentUserLevel) && challengesToShowForCurrentUserLevel.length) {
      const challengesToShowForCurrentUserLevelArrayCopy = challengesToShowForCurrentUserLevel;

      challengesToShowForCurrentUserLevelArrayCopy.forEach((entry) => {
        if (entry.isSpecialEvaluation) challengesToShowForCurrentUserLevelArrayCopy.splice(challengesToShowForCurrentUserLevelArrayCopy.indexOf(entry), 1);
      });

      setChallengesWithoutEvalutaion(challengesToShowForCurrentUserLevelArrayCopy);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengesToShowForCurrentUserLevel]);

  const allClassesForCurrentUserLevel = [...liveClassesToShowForCurrentUserLevel, ...classesToShowForCurrentUserLevel];

  useEffect(() => {
    dispatch(getDashboard())
      .then((response) => {
        if (response?.error || !response.payload) throw new Error(response.error?.message || 'Ocorreu um erro ao carregar o dashboard. Faça refresh na página.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserDetails())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os detalhes do utilizador.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserRankSummary())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  return dashboardIsLoading ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <div className="flex items-start justify-between gap-5 flex-col md:flex-row">
          <UserInfo />
          {!!userData && (
            <UserStatsCards
              cards={[
                {
                  color: 'orange',
                  icon: 'medal',
                  items: [
                    {
                      color: 'orange',
                      number: userData.position
                    }
                  ],
                  title: 'Ranking'
                },
                {
                  color: 'blue',
                  icon: 'star-circle',
                  items: [
                    {
                      color: 'blue',
                      label: 'mês atual',
                      number: userMonthlyRank?.points
                    },
                    {
                      label: 'total',
                      number: userGlobalRank?.points
                    }
                  ],
                  title: 'Pontos'
                }
              ]}
              className="w-full md:max-w-[600px] mt-10 md:mt-0"
              link={{
                path: '/ranking',
                text: 'Ranking'
              }}
            />
          )}
        </div>
        {!!evaluationChallenge && (
          <ClassesSection
            className="mt-10"
            items={[evaluationChallenge]}
            showAll
            title="Desafio de Avaliação"
          />
        )}
        <hr className="h-1 border-top border-border-gray mt-16" />
        {Array.isArray(classes) && !!classes.length && (
          <ClassesSection
            className="mt-10"
            items={allClassesForCurrentUserLevel}
            link="/aulas"
            title="Aulas"
            visibleItemsNumber={3}
          />
        )}
        {Array.isArray(challengesWithoutEvalutaion) && !!challengesWithoutEvalutaion.length && (
          <ClassesSection
            className="mt-10"
            items={challengesWithoutEvalutaion}
            link="/desafios"
            title="Desafios"
            visibleItemsNumber={3}
          />
        )}
      </div>
    );
};

Dashboard.propTypes = {
  className: PropTypes.string
};

Dashboard.defaultProps = {
  className: null
};


export default Dashboard;
