import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPointsTransactions, fetchProducts, makeTransaction } from 'api/marketplace';


const formatProducts = (items) => {
  const formattedItem = items.map((entry) => {
    let date = null;

    if (entry.date_added) {
      date = new Date(entry.date_added).toLocaleDateString('pt-PT');
    }

    return ({
      ...entry,
      date,
      displayOrder: entry.display_order ?? null,
      imageLink: entry.image ?? null,
      isActive: entry.is_active ?? null,
      isStared: entry.is_stared ?? null,
      lastUpdated: entry.last_updated ?? null
    });
  });

  return formattedItem;
};

const formatTransactions = (items) => {
  const formattedItem = items.map((entry) => {
    let date = null;
    let transaction = null;

    if (entry.date_added) {
      date = new Date(entry.date_added).toLocaleString('pt-PT');
    }

    if (entry.transaction) {
      transaction = {
        ...entry.transaction,
        date,
        dateAdded: entry.date_added,
        item: {
          ...entry.transaction?.product,
          name: entry.transaction?.product?.name
        },
        totalPoints: entry.points
      };
    } else if (entry.submission) {
      transaction = {
        ...entry.submission,
        date,
        dateAdded: entry.date_added,
        item: {
          ...entry.submission?.challenge,
          name: entry.submission?.challenge?.title
        },
        totalPoints: entry.points
      };
    }

    return transaction;
  });

  return formattedItem;
};

// Thunks
export const getPointsTransactions = createAsyncThunk(
  'marketplace/getPointsTransactions',
  pageNumber => fetchPointsTransactions(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatTransactions(response.results);
      }

      return {
        transactions: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const getProducts = createAsyncThunk(
  'marketplace/getProducts',
  pageNumber => fetchProducts(pageNumber)
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.results)) {
        payload = formatProducts(response.results);
      }

      return {
        products: payload,
        pages: {
          next: response.next,
          previous: response.previous
        }
      };
    })
);

export const setTransaction = createAsyncThunk(
  'marketplace/setTransaction',
  ({
    product,
    quantity
  }) => makeTransaction({
    product,
    quantity
  })
    .then((response) => {
      let payload = null;

      if (response) {
        payload = response;
      }

      return payload;
    })
);

export const marketplace = createSlice({
  name: 'marketplace',
  initialState: {
    allProducts: null,
    allPointsTransactions: null,
    initialized: false,
    isLoadingPointsTransactions: false,
    isLoadingProducts: false,
    isLoadingTransaction: false,
    isLoadingTransactions: false,
    pointsTransactionsPages: null,
    productsPages: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPointsTransactions.pending, (state) => {
        state.isLoadingPointsTransactions = true;
      })
      .addCase(getPointsTransactions.fulfilled, (state, action) => {
        if (action.payload) {
          const { transactions, pages } = action.payload;

          if (transactions) state.allPointsTransactions = transactions;
          if (pages) {
            state.pointsTransactionsPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingPointsTransactions = false;
      })
      .addCase(getPointsTransactions.rejected, (state) => {
        state.isLoadingPointsTransactions = false;
      })
      .addCase(getProducts.pending, (state) => {
        state.isLoadingProducts = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        if (action.payload) {
          const { products, pages } = action.payload;

          if (products) state.allProducts = products;
          if (pages) {
            state.productsPages = {
              next: pages.next || null,
              previous: pages.previous || null
            };
          }
        }

        state.isLoadingProducts = false;
      })
      .addCase(getProducts.rejected, (state) => {
        state.isLoadingProducts = false;
      })
      .addCase(setTransaction.pending, (state) => {
        state.isLoadingTransaction = true;
      })
      .addCase(setTransaction.fulfilled, (state) => {
        state.isLoadingTransaction = false;
      })
      .addCase(setTransaction.rejected, (state) => {
        state.isLoadingTransaction = false;
      });
  }
});

// Selectors
export const selectAllProducts = state => state.marketplace.allProducts;
export const selectAllPointsTransactions = state => state.marketplace.allPointsTransactions;
export const selectPointsTransactionsPages = state => state.marketplace.pointsTransactionsPages;
export const selectProductsAreLoading = state => state.marketplace.isLoadingProducts;
export const selectProductsPages = state => state.marketplace.productsPages;
export const selectTransactionIsLoading = state => state.marketplace.isLoadingTransaction;
export const selectTransactionsIsLoading = state => state.marketplace.isLoadingTransactions;

export default marketplace.reducer;
