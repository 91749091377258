import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';


const HowItWorks = ({
  className,
  noShadow
}) => (
  <div className={`${className ? ` ${className}` : ''}`}>
    <h2 className="mb-10">
        Como funciona
    </h2>
    <Card
      className="h-full p-10 md:p-28"
      noShadow={noShadow}
      smallPadding
    >
      <h5 className="mb-4 max-w-2xl ml-auto mr-auto text-center">
        O GAB Training é uma plataforma exclusiva e gratuita do Grupo Alves Bandeira que tem como objetivo promover um estilo de vida saudável, junto dos seus colaboradores.
      </h5>
      <h5 className="mb-20 md:mb-28 max-w-2xl ml-auto mr-auto text-center">
        E é promovida e gerida por um Personal Trainer exclusivo.
      </h5>
      <div className="grid gap-20 md:gap-32">
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96"
            src="/loginboth.png"
          />
          <p className="text-base max-w-md">
            Participe nas nossas atividades em qualquer lugar, sem necessidade de ter equipamento de treino consigo.
          </p>
        </div>
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96 md:order-2"
            src="/mockups-dashboard.png"
          />
          <p className="text-base max-w-md">
            Temos três tipos de atividades para si que partilham o mesmo objetivo: promover um estilo de vida mais saudável, com mais atividade física.
          </p>
        </div>
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96"
            src="/mockups-aulas.png"
          />
          <div>
            <p className="text-base max-w-md">
              Todos os meses iremos colocar à sua disposição um conjunto de aulas que poderá realizar ao seu ritmo. Por cada aula terminada receberá pontos.
            </p>
            <p className="text-base max-w-md mt-4">
              Adicionalmente teremos também aulas ao vivo, esporádicas onde poderá acompanhar o PT na realização dos exercícios.
            </p>
          </div>
        </div>
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96 md:order-2"
            src="/mockups-desafios.png"
          />
          <div>
            <p className="text-base max-w-md">
              Nos desafios poderá colocar-se à prova. Neste tipo de atividade poderá ganhar mais pontos, mas terá de submeter um vídeo em como superou o desafio. Depois de ter submetido o vídeo, terá de esperar que a nossa equipa analise o seu desempenho e valide a sua submissão.
            </p>
            <p className="text-base max-w-md mt-4">
              Periodicamente terá também de participar no desafio de avaliação. Com base no seu desempenho, ficará no nível que mais se adequa à sua performance.
            </p>
          </div>
        </div>
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96"
            src="/mockups-loja.png"
          />
          <div>
            <p className="text-base max-w-md">
              Com os pontos que vai ganhando ao participar nas nossas atividades, poderá adquirir prémios fantásticos na nossa loja GAB Training.
            </p>
          </div>
        </div>
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96 md:order-2"
            src="/rankinggab.png"
          />
          <div>
            <p className="text-base max-w-md">
              E para além disso lutar pelas melhores posições no ranking mensal e global partilhado entre todo o grupo!
            </p>
          </div>
        </div>
        <div className="grid md:grid-flow-col items-center justify-items-center gap-8">
          <img
            alt="Como funciona"
            className="h-auto w-auto max-h-44 md:max-h-96"
            src="/mockups-dicas-e-novidades.png"
          />
          <div>
            <p className="text-base max-w-md">
              Preparámos ainda para si a nossa secção de Dicas e Novidades onde encontrará notícias relacionadas com este programa, bem como conselhos de um estilo de vida saudável.
            </p>
          </div>
        </div>
      </div>
      <h5 className="mt-20 md:mt-28 max-w-2xl ml-auto mr-auto text-center">
        Comece já hoje a treinar, a ganhar prémios e a contribuir para o seu bem estar.
      </h5>
      <h5 className="mt-4 max-w-2xl ml-auto mr-auto text-center">
        Corpo São. Mente Sã.
      </h5>
    </Card>
  </div>
);

HowItWorks.propTypes = {
  className: PropTypes.string,
  noShadow: PropTypes.bool
};

HowItWorks.defaultProps = {
  className: null,
  noShadow: false
};


export default HowItWorks;
