import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'components/BaseButton';

// Icons
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';


const Pagination = ({ className, nextPage, onNextPageClick, onPreviousPageClick, previousPage }) => (
  <div className={`${className ? ` ${className}` : ''}`}>
    <div className="flex items-center justify-center gap-2">
      <Button
        className={`rounded-full h-12 w-12 text-white border border-blue${!previousPage ? ' disabled pointer-events-none' : ''}`}
        disabled={!previousPage}
        icon={<ArrowLeft />}
        onClick={onPreviousPageClick}
      />
      <Button
        className={`rounded-full h-12 w-12 text-white border border-blue${!nextPage ? ' disabled pointer-events-none' : ''}`}
        disabled={!nextPage}
        icon={<ArrowRight />}
        onClick={onNextPageClick}
      />
    </div>
  </div>
);

Pagination.propTypes = {
  className: PropTypes.string,
  nextPage: PropTypes.string,
  onNextPageClick: PropTypes.func,
  onPreviousPageClick: PropTypes.func,
  previousPage: PropTypes.string
};

Pagination.defaultProps = {
  className: null,
  nextPage: null,
  onNextPageClick: () => {},
  onPreviousPageClick: () => {},
  previousPage: null
};

export default Pagination;
