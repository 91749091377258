import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUserProfile } from 'store/slices/user';

// Utils
import { menuItems } from 'utils/MenuItems';

// Components
import BaseLink from 'components/BaseLink';
import DropdownMenu from 'components/DropdownMenu';


const Menu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector(selectUserProfile);

  const activeLinkStyles = 'pointer-events-none outline-none opacity-50';
  const dropdownLinkStyles = 'block rounded-xl p-3 mb-1 last-of-type:mb-0 hover:bg-light-blue/20 focus:bg-light-blue/20 outline-none';
  const dropdownTriggerLinkActiveHoverStyles = 'active:bg-transparent focus:bg-transparent hover:bg-transparent';
  const linkStyles = 'relative transition-color duration-500 easy-in-out outline-none focus:outline-transparent';
  const linkActiveHoverStyles = 'hover:bg-transparent';
  const storeLinkStyle = 'px-8 py-3 rounded-xl border border-white active:bg-transparent focus:bg-transparent hover:bg-white hover:text-light-blue';

  const handleLogOutClick = () => dispatch(logout());

  if (!Array.isArray(menuItems) || !menuItems.length) return null;

  const publicMenuItems = menuItems.filter(item => item.showWithoutUserProfile);
  const publicMenuItemsAndSubMenus = Array.isArray(publicMenuItems) && publicMenuItems.map(item => ({
    ...item,
    items: Array.isArray(item.items) && item.items.filter(subMenu => subMenu.showWithoutUserProfile)
  }));
  const menuItemsToShow = userProfile.username ? menuItems : publicMenuItemsAndSubMenus;

  return (
    <div className="hidden xl:flex">
      <ul className="inline-flex items-center p-0">
        {Array.isArray(menuItemsToShow) && menuItemsToShow.map((menuLink, menuLinkIndex) => (
          <React.Fragment key={menuLinkIndex}>
            {menuLink.path === '/loja' && (
              <div className="relative h-8 w-[1px] bg-border-gray mx-4 z-10" />
            )}
            <li className="mx-3">
              {!!menuLink && Array.isArray(menuLink.items) ?
                (
                  <DropdownMenu
                    buttonText={menuLink.text}
                    className={`base-link text-white ${linkStyles} ${dropdownTriggerLinkActiveHoverStyles}`}
                  >
                    {menuLink.items.map((subMenu, subMenuIndex) => (
                      <BaseLink
                        key={subMenuIndex}
                        className={dropdownLinkStyles}
                        disabled={location.pathname === subMenu.path}
                        path={subMenu.path}
                        text={subMenu.text}
                        textColor={subMenu?.textColor}
                        onClick={subMenu?.action === 'logout' ? handleLogOutClick : () => {}}
                      />
                    ))}
                  </DropdownMenu>
                ) :
                (
                  <BaseLink
                    className={`${menuLink.path === '/loja' ? `${linkStyles} ${storeLinkStyle}` : `${linkActiveHoverStyles}`}${location.pathname === menuLink.path ? ` ${activeLinkStyles}` : ` ${linkStyles} `}`}
                    disabled={location.pathname === menuLink.path}
                    path={menuLink.path}
                    text={menuLink.text}
                    textColor={menuLink.textColor}
                  />
                )}
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
