import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseLink from 'components/BaseLink';
import Card from 'components/Card';


const NewsCard = ({
  className,
  date,
  id,
  image,
  summary,
  noShadow,
  title
}) => {
  const summaryWithoutHTML = summary.replace(/<(.|\n)*?>/g, '');
  const shortSummary = summaryWithoutHTML.split(' ')
    .slice(0, 20)
    .join(' ');

  return (
    <div className={`${className ? ` ${className}` : ''}`}>
      <Card
        className="h-full"
        noShadow={noShadow}
        smallPadding
      >
        <img
          alt="Novidade"
          className="w-full rounded-md object-cover aspect-video"
          src={image}
        />
        <div className="my-5">
          <h5 className="text-light-blue mb-1">
            {title}
          </h5>
          <p className="font-semibold text-orange">
            {date}
          </p>
        </div>
        <p className="text-text-gray mb-5">
          {shortSummary}
          …
        </p>
        <BaseLink
          arrowPosition="right"
          hasArrow
          path={`/novidade/${id}`}
          text="Ler mais"
          textColor="dark-blue"
        />
      </Card>
    </div>
  );
};

NewsCard.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  noShadow: PropTypes.bool,
  summary: PropTypes.string,
  title: PropTypes.string
};

NewsCard.defaultProps = {
  className: null,
  date: null,
  id: null,
  image: null,
  noShadow: false,
  summary: null,
  title: null
};


export default NewsCard;
