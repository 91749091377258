import React, { useEffect } from 'react';

// Icons
import { ReactComponent as Loading } from 'assets/icons/loading.svg';


const FullScreenLoader = () => {
  useEffect(() => {
    if (document?.documentElement) {
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-dark-blue/[0.5] z-50">
      <div className="absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4">
        <Loading className="animate-spin h-12 w-12" />
      </div>
    </div>
  );
};

export default FullScreenLoader;
