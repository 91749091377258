import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseLink from 'components/BaseLink';
import ClassCard from 'components/ClassCard';


const ClassesSection = ({
  className,
  items,
  link,
  showAll,
  title,
  visibleItemsNumber
}) => {
  if (!Array.isArray(items)) return null;

  const itemsWithDisplayOrder = items.filter(item => item.displayOrder > 0);
  const itemsSortedByDisplayOrder = itemsWithDisplayOrder.sort((a, b) => {
    if (a.displayOrder < b.displayOrder) return -1;
    if (a.displayOrder > b.displayOrder) return 1;

    return 0;
  });
  const liveClassItems = items.filter(item => !!item.challengeType === '3');

  const allItems = [...liveClassItems, ...itemsSortedByDisplayOrder, ...items];
  const uniqueItems = [...new Set(allItems)];

  const slicedClasses = uniqueItems.slice(0, visibleItemsNumber);
  const displayedClasses = showAll ? uniqueItems : slicedClasses;

  return (
    <div className={`${className ? ` ${className}` : ''}`}>
      {!!title && (
        <h2 className="mb-10">
          {title}
        </h2>
      )}
      {Array.isArray(displayedClasses) && !!displayedClasses.length ?
        (
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            {displayedClasses.map((item, index) => (
              <ClassCard
                key={index}
                duration={item.duration}
                finished={!!item.points_given || item.isValidated}
                id={item.id}
                image={item.image}
                liveStartDate={item.liveStartDate}
                month={item.activeMonth}
                points={item.points}
                title={item.title}
                type={item.challengeType}
              />
            ))}
          </div>
        ) :
        (
          <p>
            Não foram encontrados dados.
          </p>
        )}
      {!!link && !showAll && (
        <div className="flex justify-end mt-8">
          <BaseLink
            arrowPosition="right"
            hasArrow
            path={link}
            text="Ver tudo"
          />
        </div>
      )}
    </div>
  );
};

ClassesSection.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.string,
    finished: PropTypes.bool,
    id: PropTypes.number,
    liveStartDate: PropTypes.string,
    month: PropTypes.string,
    points: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string
  })).isRequired,
  link: PropTypes.string,
  showAll: PropTypes.bool,
  title: PropTypes.string,
  visibleItemsNumber: PropTypes.number
};

ClassesSection.defaultProps = {
  className: null,
  link: null,
  showAll: false,
  title: null,
  visibleItemsNumber: null
};


export default ClassesSection;
