import React from 'react';
import PropTypes from 'prop-types';

// Components
import BaseLink from 'components/BaseLink';
import Card from 'components/Card';


const ProductCard = ({
  className,
  id,
  imageLink,
  isStared,
  noShadow,
  name,
  points,
  stock
}) => (
  <div className={className ? className : ''}>
    <Card
      className={`h-full${isStared ? ' pt-8' : ''}${!stock ? ' pointer-events-none saturate-0' : ''}`}
      noShadow={noShadow}
      smallPadding
    >
      <>
        {!stock && (
          <div className="absolute h-full w-full flex items-center justify-center top-0 left-0 bg-text-gray/40 pointer-events-none z-10">
            <h4 className="relative text-white text-center drop-shadow z-20 ">
              Produto
              <br />
              Indisponível
            </h4>
          </div>
        )}
        {isStared && (
          <>
            <div className="absolute top-0 left-0 w-full bg-orange h-3" />
            <div className="text-xs text-orange border border-orange w-fit rounded-3xl px-2 py-1 mb-5">
              Destaque
            </div>
          </>
        )}
        <img
          alt="Produto"
          className="w-full rounded-md object-cover aspect-video"
          src={imageLink}
        />
        <h4 className="my-5">
          {name}
        </h4>
        <div className="flex grow items-end justify-between">
          <p className="font-semibold text-orange">
            {points}
            {' '}
            pontos
          </p>
          <BaseLink
            arrowPosition="right"
            hasArrow
            path={`/produto/${id}`}
            text="Ver detalhes"
            textColor="dark-blue"
          />
        </div>
      </>
    </Card>
  </div>
);

ProductCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  imageLink: PropTypes.string,
  isStared: PropTypes.bool,
  name: PropTypes.string,
  noShadow: PropTypes.bool,
  points: PropTypes.number,
  stock: PropTypes.number
};

ProductCard.defaultProps = {
  className: null,
  id: null,
  imageLink: null,
  isStared: false,
  name: null,
  noShadow: false,
  points: null,
  stock: null
};


export default ProductCard;
