import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';


const BaseSelect = forwardRef(({
  className,
  disabled,
  onChange,
  error,
  label,
  options,
  placeholder,
  ...props
}, ref) => {
  if (!Array.isArray(options) || !options.length) return null;

  const inputClasses = `input appearance-none bg-background-gray my-2 w-full focus:shadow-input${disabled ? ' text-text-gray' : ''}${error ? ' border-solid border-2 border-error' : ''}`;
  const labelClasses = 'label p-0';
  const labelTextClasses = `label-text font-semibold text-light-blue${disabled ? ' opacity-50' : ''}`;

  return (
    <div className={`form-control w-full${className ? ` ${className}` : ''}`}>
      {!!label && (
        <label className={labelClasses}>
          <span className={labelTextClasses}>
            {label}
          </span>
        </label>
      )}
      {!!error && (
        <span className="text-xs text-error">
          {error}
        </span>
      )}
      <div className="relative">
        <select
          ref={ref}
          className={inputClasses}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        >
          {options.map(option => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.text || option.value}
            </option>
          ))}
        </select>
        <ChevronDown className="absolute top-1/2 right-4 -translate-y-1/2 w-3 h-auto pointer-events-none" />
      </div>
    </div>
  );
});

BaseSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })),
  placeholder: PropTypes.string
};

BaseSelect.defaultProps = {
  className: null,
  disabled: false,
  error: null,
  icon: null,
  label: null,
  link: null,
  onChange: () => {},
  options: null,
  placeholder: null
};

BaseSelect.displayName = 'BaseSelect';

export default BaseSelect;
