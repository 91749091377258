import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthenticationToken } from 'store/slices/user';

// Components
import BaseLink from 'components/BaseLink';


const NotFoundPage = () => {
  const isLoggedIn = useSelector(selectAuthenticationToken);

  return (
    <section className={`min-w-full flex items-center justify-center ${!isLoggedIn ? 'min-h-screen  bg-gradient-to-r from-blue to-light-blue' : ''}`}>
      <div className="container">
        <div className="max-w-md mx-auto text-center mb-10">
          <h2 className={`${!isLoggedIn ? 'text-white ' : ''}`}>
            Ups! Ocorreu um erro.
          </h2>
          <p className={`${!isLoggedIn ? 'text-white' : ''}`}>
            A página que tentou aceder não se encontra disponível.
          </p>
          <BaseLink
            arrowPosition="left"
            className={`mt-5${!isLoggedIn ? ' text-white' : ''}`}
            hasArrow
            path="/dashboard"
            text="Ir para o início"
            textColor={`${isLoggedIn ? 'dark-blue' : ''}`}
          />
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
