/* eslint-disable camelcase */
import { axiosGet, axiosGetNoHeaders, axiosPost, axiosPostNoHeaders } from 'api';
import {
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_RECOVER_PASSWORD,
  AUTHENTICATION_REFRESH,
  AUTHENTICATION_SIGN_UP,
  AUTHENTICATION_UPDATE_PASSWORD,
  LOGIN_BANNER,
  PERSONAL_DETAIL
} from 'api/endpoints';


const fetchLoginBanner = () => axiosGetNoHeaders(LOGIN_BANNER);

const fetchUserDetails = () => axiosGet(PERSONAL_DETAIL);

const recoverUserPassword = ({
  email
}) => axiosPostNoHeaders(AUTHENTICATION_RECOVER_PASSWORD, {
  email
});

const refreshAuthToken = refreshToken => axiosPost(AUTHENTICATION_REFRESH, { refresh: refreshToken });

const updateUserDetails = ({
  address,
  company,
  firstName,
  height,
  lastName,
  locality,
  photo,
  points,
  postalCode,
  telephone,
  username,
  userRecord,
  weight
}) => {
  const data = {
    firstname: firstName,
    lastname: lastName,
    username
  };

  if (address) data.address = address;
  if (company) data.company = company;
  if (height) data.height = height;
  if (locality) data.locality = locality;
  if (photo) data.photo = photo;
  if (points) data.points = points;
  if (postalCode) data.postal_code = postalCode;
  if (telephone) data.telephone = telephone;
  if (userRecord) data.user_record = userRecord;
  if (weight) data.weight = weight;

  return axiosPost(PERSONAL_DETAIL, photo ? photo : data, null, photo ? { 'Content-Type': 'multipart/form-data' } : null);
};

const updateUserPassword = ({
  password
}) => axiosPost(AUTHENTICATION_UPDATE_PASSWORD, {
  password
});

const userLogin = ({
  email,
  password
}) => axiosPost(AUTHENTICATION_LOGIN, {
  email,
  password
});

const userSignUp = ({
  email,
  password
}) => {
  const data = {
    email,
    password
  };

  return axiosPost(AUTHENTICATION_SIGN_UP, data);
};

export {
  fetchLoginBanner,
  fetchUserDetails,
  recoverUserPassword,
  refreshAuthToken,
  updateUserDetails,
  updateUserPassword,
  userLogin,
  userSignUp
};
