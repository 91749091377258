import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getRankSummary, getUserRankSummary, selectRankSummary, selectRankSummaryIsLoading, selectRankUserSummary } from 'store/slices/rank';
import { selectUserProfile } from 'store/slices/user';

// Components
import BaseLink from 'components/BaseLink';
import Card from 'components/Card';
import FullScreenLoader from 'components/FullScreenLoader';
import UserInfo from 'components/UserInfo';
import UserStatsCards from 'components/UserStatsCards';

// Icons
import userIcon from 'assets/icons/user.svg';
import { ReactComponent as ThreeDots } from 'assets/icons/three-dots.svg';


const API_IMAGES_URL = 'https://d1nbb270y0xfhp.cloudfront.net/';

const Ranking = ({
  className
}) => {
  const dispatch = useDispatch();
  const isLoadingRankingSummary = useSelector(selectRankSummaryIsLoading);
  const rankSummary = useSelector(selectRankSummary);
  const rankUserSummary = useSelector(selectRankUserSummary);
  const user = useSelector(selectUserProfile);

  const globalRank = rankSummary?.globalRank;
  const monthRank = rankSummary?.monthRank;
  const userGlobalRank = rankUserSummary?.userGlobalRank;
  const userMonthlyRank = rankUserSummary?.userMonthlyRank;

  useEffect(() => {
    dispatch(getRankSummary())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserRankSummary())
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Ocorreu um erro ao obter os rankings.');

        return response;
      })
      .then(response => response)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [dispatch]);

  return isLoadingRankingSummary ?
    (
      <FullScreenLoader />
    ) :
    (
      <div className={`${className ? ` ${className}` : ''}`}>
        <UserInfo />
        <UserStatsCards
          cards={[
            {
              color: 'blue',
              icon: 'star-circle',
              items: [
                {
                  color: 'blue',
                  label: 'mês atual',
                  number: userMonthlyRank?.points
                },
                {
                  label: 'total',
                  number: userGlobalRank?.points
                }
              ],
              title: 'Pontos'
            }
          ]}
          className="mt-10"
          link={{
            path: '/movimentos',
            text: 'Movimentos'
          }}
        />
        {Array.isArray(monthRank) && monthRank.length > 0 && (
          <>
            <h2 className="my-10">
              Ranking Mensal
            </h2>
            <Card smallPadding>
              <>
                {monthRank.map((entry, index) => (
                  <div
                    key={index}
                    className={`flex px-3 py-2 items-center justify-between mb-4 last-of-type:mb-0${entry.username === user.username ? ' rounded-lg bg-light-blue bg-opacity-10' : ''}`}
                  >
                    <div className="inline-flex items-center">
                      <div className="font-semibold text-light-blue min-w-[30px]">
                        {entry.pointsRank}
                        .
                      </div>
                      <img
                        alt="User avatar"
                        className="h-10 w-10 mx-5 rounded-full object-cover object-center overflow-hidden"
                        src={entry.photo ? `${API_IMAGES_URL}${entry.photo}` : userIcon}
                      />
                      <div className="text-sm font-semibold text-light-blue">
                        {entry.username}
                      </div>
                    </div>
                    <div className="text-sm font-semibold text-orange">
                      {entry.totalPoints}
                      {' '}
                      pontos
                    </div>
                  </div>
                ))}
                {userMonthlyRank?.position > 5 && (
                  <>
                    <div className="flex px-3 py-2 items-center justify-center mb-4 last-of-type:mb-0 rounded-lg">
                      <ThreeDots className="h-10" />
                    </div>
                    <div className="flex px-3 py-2 items-center justify-between mb-4 last-of-type:mb-0 rounded-lg bg-light-blue bg-opacity-10">
                      <div className="inline-flex items-center">
                        <div className="font-semibold text-light-blue min-w-[30px]">
                          {userMonthlyRank.position}
                          .
                        </div>
                        <img
                          alt="User avatar"
                          className="h-10 w-10 mx-5 rounded-full object-cover object-center overflow-hidden"
                          src={user.photo ? `${user.photo}` : userIcon}
                        />
                        <div className="text-sm font-semibold text-light-blue">
                          {user.username}
                        </div>
                      </div>
                      <div className="text-sm font-semibold text-orange">
                        {userMonthlyRank.points}
                        {' '}
                        pontos
                      </div>
                    </div>
                  </>
                )}
              </>
            </Card>
            <div className="flex justify-end mt-8">
              <BaseLink
                arrowPosition="right"
                hasArrow
                path="/ranking-mensal"
                text="Ver tudo"
              />
            </div>
          </>
        )}
        {Array.isArray(globalRank) && globalRank.length > 0 && (
          <>
            <h2 className="my-10">
              Ranking Global
            </h2>
            <Card smallPadding>
              <>
                {globalRank.map((entry, index) => (
                  <div
                    key={index}
                    className={`flex px-3 py-2 items-center justify-between mb-4 last-of-type:mb-0${entry.username === user.username ? ' rounded-lg bg-light-blue bg-opacity-10' : ''}`}
                  >
                    <div className="inline-flex items-center">
                      <div className="font-semibold text-light-blue min-w-[30px]">
                        {entry.pointsRank}
                        .
                      </div>
                      <img
                        alt="User avatar"
                        className="h-10 w-10 mx-5 rounded-full object-cover object-center overflow-hidden"
                        src={entry.photo ? `${API_IMAGES_URL}${entry.photo}` : userIcon}
                      />
                      <div className="text-sm font-semibold text-light-blue">
                        {entry.username}
                      </div>
                    </div>
                    <div className="text-sm font-semibold text-orange">
                      {entry.totalPoints}
                      {' '}
                      pontos
                    </div>
                  </div>
                ))}
                {userGlobalRank?.position > 5 && (
                  <>
                    <div className="flex px-3 py-2 items-center justify-center mb-4 last-of-type:mb-0 rounded-lg">
                      <ThreeDots className="h-10" />
                    </div>
                    <div className="flex px-3 py-2 items-center justify-between mb-4 last-of-type:mb-0 rounded-lg bg-light-blue bg-opacity-10">
                      <div className="inline-flex items-center">
                        <div className="font-semibold text-light-blue min-w-[30px]">
                          {userGlobalRank.position}
                          .
                        </div>
                        <img
                          alt="User avatar"
                          className="h-10 w-10 mx-5 rounded-full object-cover object-center overflow-hidden"
                          src={user.photo ? `${API_IMAGES_URL}${user.photo}` : userIcon}
                        />
                        <div className="text-sm font-semibold text-light-blue">
                          {user.username}
                        </div>
                      </div>
                      <div className="text-sm font-semibold text-orange">
                        {userGlobalRank.points}
                        {' '}
                        pontos
                      </div>
                    </div>
                  </>
                )}
              </>
            </Card>
            <div className="flex justify-end mt-8">
              <BaseLink
                arrowPosition="right"
                hasArrow
                path="/ranking-global"
                text="Ver tudo"
              />
            </div>
          </>
        )}
      </div>
    );
};

Ranking.propTypes = {
  className: PropTypes.string
};

Ranking.defaultProps = {
  className: null
};


export default Ranking;
