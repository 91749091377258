import React from 'react';
import PropTypes from 'prop-types';

// Components
import Card from 'components/Card';


const Transactions = ({
  className,
  transactions
}) => {
  if (!Array.isArray(transactions) || !Array.length) return null;

  const sortedTransactionsByDate = transactions.slice().sort((a, b) => {
    if (a.dateAdded < b.dateAdded) return 1;
    if (a.dateAdded > b.dateAdded) return -1;

    return 0;
  });

  return (
    <div className={className ? className : ''}>
      <Card smallPadding>
        {sortedTransactionsByDate.map((entry, index) => (
          <div
            key={index}
            className="py-4 border-b border-gray last-of-type:border-0 last-of-type:pb-0 first-of-type:pt-0"
          >
            <p className="font-semibold text-light-blue">
              {entry.date}
            </p>
            <div className="flex justify-between">
              <div className="font-semibold text-sm text-dark-blue">
                {entry.item.name}
                <span className="font-semibold text-sm text-text-gray ml-2">
                  {entry.product && (
                    'x'
                  )}
                  {entry.quantity}
                </span>
              </div>
              {entry.product && (
                <p className="font-semibold text-orange">
                  -
                  {entry.totalPoints}
                </p>
              )}
              {entry.challenge && (
                <p className="font-semibold text-light-blue">
                  +
                  {entry.totalPoints}
                </p>
              )}
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
};

Transactions.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    product: PropTypes.shape({
      name: PropTypes.string
    }),
    quantity: PropTypes.number,
    totalPoints: PropTypes.number
  }))
};

Transactions.defaultProps = {
  className: null,
  transactions: null
};


export default Transactions;
