import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';


const DropdownMenu = ({
  buttonText,
  children,
  className
}) => {
  const dropdown = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  if (!children) return null;

  const handleDropdownLinkMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleDropdownLinkMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <span
      className={`relative pb-4 z-10 hover:opacity-100${className ? ` ${className}` : ''}`}
      role="button"
      tabIndex="0"
      onMouseEnter={handleDropdownLinkMouseEnter}
      onMouseLeave={handleDropdownLinkMouseLeave}
    >
      {buttonText}
      {showDropdown && (
        <div className="absolute right-0 pt-4">
          <ul
            ref={dropdown}
            className="shadow-lg p-1 bg-white rounded-2xl min-w-[150px]"
          >
            {children}
          </ul>
        </div>
      )}
    </span>
  );
};

DropdownMenu.propTypes = {
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string
};

DropdownMenu.defaultProps = {
  children: null,
  className: null
};


export default DropdownMenu;
