import { configureStore } from '@reduxjs/toolkit';
import classes from './slices/classes';
import dashboard from './slices/dashboard';
import marketplace from './slices/marketplace';
import news from './slices/news';
import rank from './slices/rank';
import user from './slices/user';


export default configureStore({
  reducer: {
    classes,
    dashboard,
    marketplace,
    news,
    rank,
    user
  }
});
