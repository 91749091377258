import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchDashboard from 'api/dashboard';

// Utils
import capitalizeFirstLetter from 'utils/CapitalizeFirstLetter';
import formatDuration from 'utils/FormatDuration';


const formatDashboardItems = (items) => {
  const formattedItem = items.map((entry) => {
    let month = null;

    if (entry.active_month) {
      const date = new Date(entry.active_month);

      month = capitalizeFirstLetter(date.toLocaleString('pt-pt', { month: 'long' }));
    }

    return ({
      ...entry,
      activeMonth: month,
      challengeType: entry.challenge_type ?? null,
      dateAdded: entry.date_added ?? null,
      displayOrder: entry.display_order ?? null,
      duration: entry.duration ? formatDuration(entry.duration) : null,
      isActive: entry.is_active ?? null,
      isSpecialEvaluation: entry.is_special_evaluation ?? null,
      isValidated: entry.is_validated ?? null,
      lastUpdated: entry.last_updated ?? null,
      liveEndDate: entry.live_end_date ?? null,
      liveStartDate: entry.live_start_date ?? null,
      pointsGiven: entry.points_given ?? null,
      videoLink: entry.videoLink ?? null
    });
  });

  return formattedItem;
};

// Thunks
export const getDashboard = createAsyncThunk(
  'dashboard/getDashboard',
  () => fetchDashboard()
    .then((response) => {
      let payload = null;

      if (response && Array.isArray(response.classes) && Array.isArray(response.challenges) && response.rank) {
        const classes = formatDashboardItems(response.classes);
        const challenges = formatDashboardItems(response.challenges);
        const lives = formatDashboardItems(response.lives);
        const userData = {
          ...response.rank,
          points: response.rank.points ?? 0,
          position: response.rank.position ?? '-'
        };

        payload = {
          classes,
          challenges,
          lives,
          userData
        };
      }

      return payload;
    })
);

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    allClasses: null,
    allChallenges: null,
    allLiveClasses: null,
    initialized: false,
    isLoadingDashboard: false,
    userData: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.isLoadingDashboard = true;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        if (action.payload) {
          state.allChallenges = action.payload.challenges;
          state.allClasses = action.payload.classes;
          state.allLiveClasses = action.payload.lives;
          state.userData = action.payload.userData;
        }

        state.isLoadingDashboard = false;
      })
      .addCase(getDashboard.rejected, (state) => {
        state.isLoadingDashboard = false;
      });
  }
});

// Selectors
export const selectAllClasses = state => state.dashboard.allClasses;
export const selectAllChallenges = state => state.dashboard.allChallenges;
export const selectAllLiveClasses = state => state.dashboard.allLiveClasses;
export const selectDashboardIsLoading = state => state.dashboard.isLoadingDashboard;
export const selectDashboardUserData = state => state.dashboard.userData;

export default dashboard.reducer;
