import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';


const BaseLink = forwardRef(({
  arrowPosition,
  className,
  external,
  hasArrow,
  path,
  onClick,
  text,
  textColor,
  type,
  ...props
}, ref) => {
  const classes = `base-link disabled:opacity-30 disabled:pointer-events-none${
    hasArrow ? ' inline-flex items-center gap-2' : ''}${
    textColor === 'light-blue' ? ' text-light-blue hover:text-blue' : ''}${
    textColor === 'dark-blue' ? ' text-dark-blue hover:text-darker-blue' : ''}${
    textColor === 'white' ? ' text-white' : ''
  }${className ? ` ${className}` : ''}`;

  if (type === 'button') {
    return (
      <button
        ref={ref}
        className={classes}
        type="button"
        onClick={onClick}
        {...props}
      >
        {hasArrow && arrowPosition === 'left' && (
          <ArrowLeft />
        )}
        {text}
        {hasArrow && arrowPosition === 'right' && (
          <ArrowRight />
        )}
      </button>
    );
  }

  if (external) {
    return (
      <a
        className={classes}
        href={path}
        rel="noreferrer noopener"
        target="_blank"
      >
        {text}
      </a>
    );
  }

  return (
    <Link
      className={classes}
      to={path}
      onClick={onClick}
      {...props}
    >
      {hasArrow && arrowPosition === 'left' && (
        <ArrowLeft />
      )}
      {text}
      {hasArrow && arrowPosition === 'right' && (
        <ArrowRight />
      )}
    </Link>
  );
});

BaseLink.propTypes = {
  arrowPosition: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  external: PropTypes.bool,
  hasArrow: PropTypes.bool,
  onClick: PropTypes.func,
  path: PropTypes.string,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.oneOf(['dark-blue', 'light-blue', 'white']),
  type: PropTypes.string
};

BaseLink.defaultProps = {
  arrowPosition: 'right',
  className: null,
  external: false,
  hasArrow: false,
  onClick: null,
  path: '/dashboard',
  textColor: 'light-blue',
  type: null
};

BaseLink.displayName = 'BaseLink';

export default BaseLink;
